<template>
  <div v-if="showPopup" class="cookie-popup">
    <button @click="closePopup" class="cookie-popup__remove-btn remove-btn" type="button"></button>
    <h3 class="small-sub-title-font-size bold-font">{{ title }}</h3>
    <p v-if="descriptionExist" class="mt-5 small-font-size" v-html="description"></p>
    <button @click.prev="btnClick" class="small-btn action-btn mt-10" type="button">{{ btnName }}</button>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ConfirmPopup',

  props: {
    title: { type: String, require: true },
    description: { type: String, require: false },
    btnName: { type: String, require: true },
    btnUrl: { type: String, require: true },
    btnUrlMethod: { type: String, require: false }
  },

  data() {
    return {
      showPopup: true
    }
  },

  methods: {
    descriptionExist() {
      return this.description.length > 0
    },

    urlMethod() {
      return this.btnUrlMethod.length > 0 ? this.btnUrlMethod : "GET"
    },

    closePopup() {
      this.showPopup = false
    },

    btnClick() {
      axios.request({
        response_type: 'json',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        url: this.btnUrl,
        method: this.urlMethod()
      }).then((response) => {
        this.closePopup()
      })
    }
  },
}
</script>
