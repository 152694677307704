<script>
import refferalCourses from '../../common/refferal-courses.js';

export default {
	name: 'RefferalBanner',
	template: `<slot v-bind="self" />`,

	props: {
		current_company: ''
	},

	computed: {
		self: function() {
			return this;
		},
	},

	data() {
		return {
			courses: refferalCourses,
			isShow: true
		}
	},

	mounted() {
		this.courses.forEach((course) => {
			if (course === this.current_company) {
				this.isShow = false;
			}
		});
	},
}
</script>
