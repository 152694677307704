<template>
	<section class="admin-widget">
		<section :class="'admin-widget__section admin-widget__section--' + invitations.status">
			<h3 class="admin-widget__label">{{ $t('used_license') }}</h3>
			<p class="admin-widget__status-holder">
				<span class="admin-widget__status">{{ invitationsValue }}%</span>
			</p>
			<div class="progress-scale admin-widget__progress">
				<div class="progress-scale__bar" :style="{ width: invitationsValue + '%' }"></div>
			</div>
			<p v-if="paid" class="admin-widget__descr">
				{{ $t('active_till') }} {{ paid_till }}
			</p>
			<div class="admin-widget__bottom">
				<a v-if="!invitations.isSend" @click.prevent="openLicenseRequest" class="action-btn regular-btn" >
					{{ invitations.buttonName }}
				</a>
				<p v-else class="sub-light-font-color uppercase-font">
					{{ $t('contact_soon') }}
				</p>
			</div>
		</section>
		<section :class="'admin-widget__section admin-widget__section--' + assignments.status">
			<h3 class="admin-widget__label">
				{{ $t('employees_without_appointments') }}
			</h3>
			<p class="admin-widget__status-holder">
				<span class="admin-widget__status">{{ assignmentsValue }}</span>
			</p>
			<p class="admin-widget__descr">
				{{ $t('avg_count_assignments', { avg_yours: avg_yours, avg_total: avg_total }) }}
			</p>
			<div class="admin-widget__bottom">
				<a @click="onAssignAllBtnClick" class="action-btn regular-btn">
					{{ $t('buttons.assign_all') }}
				</a>
			</div>
		</section>
		<section :class="'admin-widget__section admin-widget__section--' + (notificationStatus.isSend ? 'st-disabled' : assignmentsLate.status)">
			<h3 class="admin-widget__label">{{ $t('overdue_appointments') }}</h3>
			<p class="admin-widget__status-holder">
				<span class="admin-widget__status">{{ assignmentsLateValue }}</span>
			</p>
			<div class="progress-scale admin-widget__progress">
				<div class="progress-scale__bar" :style="{ width: assignmentLatePercent + '%' }"></div>
			</div>
			<div class="admin-widget__bottom">
				<div v-if="notificationStatus.isSend">
					<p class="admin-widget__descr">{{ $t('notification_send') }}</p>
					<p class="admin-widget__descr">{{ $t('check_them') }} {{ notificationStatus.date }}</p>
				</div>
				<div v-else>
					<BtnConfirmPopup
						:btn_name="$t('buttons.notify_all')"
						btn_class="regular-btn"
						:popup_title="$t('sending_notification')"
						:popup_description="$t('going_to_send') + ' ' + $t('notifications', assignments_late_value)"
						:cancel_btn_name="$t('buttons.cancel')"
						:disabled="assignmentsLateValue == 0"
					>
					<a
						@click="sendNotifications"
						:class="[assignmentsLateValue == 0 ? 'disabled-btn' : '', '']"
						class="small-btn action-btn mr-10"
					>
						{{ $t('buttons.send') }}
					</a>
					</BtnConfirmPopup>
				</div>
			</div>
		</section>
	</section>

	<section class="modal" :class="{ showing: showingLicenseRequest }">
		<div class="modal__card modal__card--small">
			<button
				@click.prev="closeLicenseRequest()"
				class="modal__close remove-btn popup-remove-btn"
				type="button"
				:aria-label="$t('close_popup')"
			></button>
			<div class="modal__header">
				<h3 class="modal__title modal__title--small">{{ $t('license_popup.title') }}</h3>
			</div>
			<div class="modal__main modal__main--lecense">
				<label class="labeled-field mb-10">
					<span class="labeled-field__label">
						<abbr title="required">*</abbr>
						{{ $t('placeholders.your_name') }}
					</span>
					<input
						type="text"
						class="labeled-field__input"
						:placeholder="first_name"
						v-model.trim="firstName"
					/>
				</label>
				<label class="labeled-field">
					<span class="labeled-field__label">
						<abbr title="required">*</abbr>
						{{  $t('placeholders.phone') }}
					</span>
					<input
						placeholder="+7 ### ### ## ##"
						id="license_phone"
						type="tel"
						class="labeled-field__input"
						:class="{ 'error-field': licenseFormErrors.includes('phone') }"
						v-model.trim="phone"
						autofocus
					>
				</label>
				<button @click.prevent="onInvitationBtnClick" class="regular-btn action-btn mt-20">
					{{ $t('license_popup.submit') }}
				</button>
				<div class="modal__license-note">
					<p v-html="$t('license_popup.message')"></p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import axios from "axios";
import BtnConfirmPopup from "../shared/BtnConfirmPopup.vue";

export default {
	name: "StartAssignmentWidget",

	components: { BtnConfirmPopup },

	props: {
		invitations_value: Number,
		users_assignments_free: Number,
		assignments_total_value: Number,
		assignments_late_value: Number,
		avg_yours: Number,
		avg_total: Number,
		invite_url: String,
		purchase_licenses_url: String,
		assign_all_url: String,
		notify_all_late_users_url: String,
		next_notify_date: String,
		can_notify_all: Boolean,
		paid: Boolean,
		paid_till: String,
		first_name: String
	},

	data() {
		return {
			invitationsValue: this.invitations_value,
			assignmentsValue: this.users_assignments_free,
			assignmentsLateValue: this.assignments_late_value,
			firstName: "",
			phone: "",
			showingLicenseRequest: false,
			licenseFormErrors: [],
			invitations: {
				href: "",
				buttonName: "",
				status: "",
				asyncRequest: false,
				isSend: false
			},
			assignments: {
				href: this.assign_all_url,
				status: "",
				avg_yours: this.avg_yours,
				avg_total: this.avg_total
			},
			assignmentsLate: {
				status: ""
			},
			notificationStatus: {
				isSend: !this.can_notify_all,
				date: this.next_notify_date
			}
		};
	},

	computed: {
		assignmentLatePercent() {
			return (this.assignments_late_value / this.assignments_total_value) * 100;
		}
	},

	mounted() {
		this.setInvitations();
		this.setAssignments();
		this.setLateAssignments();
	},

	methods: {
		setInvitations() {
			var val = this.invitationsValue;
			switch (true) {
				case val <= 40:
					this.invitations.status = "st-bad";
					this.invitations.href = this.invite_url;
					this.invitations.buttonName = this.$t('license_popup.invite');
					this.invitations.asyncRequest = false;
					break;
				case val > 40 && val < 80:
					this.invitations.status = "st-average";
					this.invitations.href = this.invite_url;
					this.invitations.buttonName = this.$t('license_popup.invite');
					this.invitations.asyncRequest = false;
					break;
				case val >= 80:
					this.invitations.status = "st-good";
					this.invitations.href = this.purchase_licenses_url;
					this.invitations.buttonName = this.$t('license_popup.purchase_licenses');
					this.invitations.asyncRequest = true;
					break;
				default:
					break;
			}
		},
		setAssignments() {
			var val = this.assignmentLatePercent;
			switch (true) {
				case val == 0:
					this.assignments.status = "st-good";
					break;
				case val > 0 && val < 60:
					this.assignments.status = "st-average";
					break;
				case val >= 60:
					this.assignments.status = "st-bad";
					break;
				default:
					break;
			}
		},

		setLateAssignments() {
			var val = this.assignments_late_value / this.assignments_total_value;
			if (this.notificationStatus.isSend == false) {
				switch (true) {
				case val == 0:
					this.assignmentsLate.status = "st-good";
					break;
				case val > 0 && val < 60:
					this.assignmentsLate.status = "st-average";
					break;
				case val >= 60:
					this.assignmentsLate.status = "st-bad";
					break;
				default:
					break;
				}
			}
		},
		openLicenseRequest() {
			this.showingLicenseRequest = true;
		},

		closeLicenseRequest() {
			this.showingLicenseRequest = false;
		},

		sendNotifications() {
			axios({
				response_type: "json",
				headers: {
				"X-Requested-With": "XMLHttpRequest",
				"X-CSRF-Token": document
					.querySelector('meta[name="csrf-token"]')
					.getAttribute("content")
				},
				method: "POST",
				url: this.notify_all_late_users_url
			})
			.then(response => {
				this.notificationStatus.isSend = true;
				this.assignmentsLate.status = "st-disabled";
				this.notificationStatus.date = response.data.next_notify_date;
				window.trackEvent("widjet_notification");
			})
			.catch(errors => {
			// console.log(errors.response.data)
			});
		},

		onAssignAllBtnClick() {
			window.trackEvent("widjet_assign");
			window.location.href = this.assignments.href;
		},

		onInvitationBtnClick() {
			if (!this.validateLicenseForm()) {
				return;
			}

			if (this.invitations.asyncRequest) {
				axios({
				response_type: "json",
				headers: {
					"X-Requested-With": "XMLHttpRequest",
					"X-CSRF-Token": document
					.querySelector('meta[name="csrf-token"]')
					.getAttribute("content")
				},
				method: "POST",
				url: this.invitations.href,
				data: {
					first_name:
					this.firstName.length > 0
						? this.firstName
						: this.first_name,
					phone: this.phone
				}
				})
				.then(response => {
					this.closeLicenseRequest();
					this.invitations.isSend = true;
					window.trackEvent("widjet_license");
				})
				.catch(errors => {
					// console.log(errors.response.data)
				});
			} else {
				window.trackEvent("widjet_invite");
				window.location.href = this.invitations.href;
			}
		},

		validateLicenseForm() {
			const phoneRegexp = /^[+\d\s()\-]+$/;

			this.licenseFormErrors = [];
			if (!phoneRegexp.test(this.phone)) {
				this.licenseFormErrors.push("phone");
			}

			return this.licenseFormErrors.length == 0;
		}
	},
};
</script>
