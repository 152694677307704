<template>
  <section class="page-card__section">
    <div class="page-card__row page-card__row--align-baseline">
      <h3 class="page-card__subtitle">{{ title }}</h3>
      <a v-if="emptyDraftsExists" :href="eraseDraftsProgramsUrl" class="link-btn"
        data-method="post" rel="nofollow" :data-confirm="areYouSure">
        {{ deleteDraftBtn }}
      </a>
    </div>

    <div class="items-in-set scrollable-shadow scrollable-shadow--wide mt-10" :class="{ 'programs-loading-holder': loadingData }">
      <div v-if="loadingData" class="loader loader--block j-loading-action">
        <div class="loader__icon"></div>
      </div>

      <ProgramItem v-for="program in programs.slice(0, countDisplayingPrograms)" :key="program.id" :program="program" />

      <button
        class="progress-item progress-item--btn-more"
        v-if="!isShowAllPrograms(programs, countDisplayingPrograms)"
        @click="showAllPrograms(programs, 'countDisplayingPrograms')"
        type="button"
      >
        {{showMore}} ({{ countHiddenPrograms(programs, countDisplayingPrograms) }})
      </button>

      <a v-if="showProgramsStub(programs)" class="progress-item progress-item--btn-more" :href="newProgramUrl">
        + {{create}}
      </a>
    </div>
  </section>
</template>

<script>
import ProgramItem from './ProgramItem.vue';

export default {
  name: 'ProgramsGroup',

  components: {
    ProgramItem
  },

  props: {
    programs: { type: Array, required: true },
    loadingData: { type: Boolean, required: true },
    title: { type: String, required: true },
    newProgramUrl: { type: String, required: true },
    create: { type: String, required: true },
    showMore: { type: String, required: true },
    deleteDraftBtn: { type: String, required: true },
    emptyDraftsExists: { type: Boolean, required: true, default: false },
    eraseDraftsProgramsUrl: { type: String, required: true },
    areYouSure: { type: String, required: true },
    isShowProgramsStubBtn: { type: Boolean, default: true },
  },

  data() {
    return {
      countDisplayingPrograms: 7,
    }
  },

  methods: {
    showAllPrograms(programs, countField) {
      this[countField] = programs.length;
    },

    isShowAllPrograms(programs, currentDisplayingCount) {;
      return (!this.isProgramsExits(programs) || programs.length <= currentDisplayingCount ? true : false);
    },

    countHiddenPrograms(programs, currentDisplayingCountPrograms) {
      return programs.length - currentDisplayingCountPrograms;
    },

    showProgramsStub(programs) {
      return !this.loadingData && !this.isProgramsExits(programs) && this.isShowProgramsStubBtn;
    },

    isProgramsExits(programs) {
      return programs.length > 0 ? true : false;
    }
  },
}
</script>
