<script>
function parseDropdownValues(values) {
  var result = [];
  if (values.length > 0) {
    for (const value of values) {
      if (value !== undefined && value.id) {
        result.push(value.id);
      }
    }
  }
  return result;
}

import axios from "axios";

export default {
  name: "CareerAspectForm",
  template: `<slot v-bind="self" />`,

  props: {
    open_preset_form: Boolean,
    default_assignee_ids: Array,
  },

  data() {
    return {
      authenticity_token: "",
      utf8: "",
      formElement: null,
      form_action: {},
      aspect: {},
      craftValues: [],
      dropdownCrafts: {},
      errors: {},
      t: I18n.t,
    };
  },

  computed: {
    self: function() {
      return this;
    },
  },

  methods: {
    setDropdownValue(dropdownName, values) {
      if (!Array.isArray(values)) {
        values = [values];
      }
      this[dropdownName].clear_selected_values();
      for (var value of values) {
        if (value) {
          this[dropdownName].add_selected_values(value);
        }
      }
      this[dropdownName].activate();
    },

    showAspectForm(aspect) {
      this.clearData();
      this.$parent.openPopup();
      this.aspect = JSON.parse(JSON.stringify(aspect));
      let dropdownCrafts;
      if (this.aspect.crafts) {
        dropdownCrafts = this.aspect.crafts.map(
          (crafts) => "Course/" + crafts.course_id
        );
      } else {
        dropdownCrafts = [];
      }

      this.setDropdownValue("dropdownCrafts", dropdownCrafts);
    },

    showAspectFormByUrl(url) {
      axios
        .request({
          response_type: "json",
          headers: { "X-Requested-With": "XMLHttpRequest" },
          url: url,
        })
        .then((response) => {
          this.aspect = JSON.parse(JSON.stringify(response.data));
          this.showAspectForm(this.aspect);
        });
    },

    clearData() {
      this.aspect = {};
      this.form_action = "";
      this.setDropdownValue("dropdownCrafts", []);
    },

    submitAspectForm() {
      this.emitter.emit("submit_aspect_form", this.aspect);
      this.aspect = {};
      this.craftValues = [];
      this.$parent.closePopup();
    },

    deleteAspect() {
      this.emitter.emit("delete_aspect", this.aspect);
      this.$parent.closePopup();
    },

    closePopup() {
      this.$parent.closePopup();
    },
  },

  mounted() {
    this.aspect.crafts = [];
    this.formElement = this.$refs.career_aspect_form;

    this.dropdownCrafts = new Dropdown(
      document.querySelector(
        "div[data-dropdown-name='j-dropdown-crafts_list']"
      ),
      (values) => {
        let selectedValues = parseDropdownValues(values);
        this.aspect.crafts_list = selectedValues;
        if (
          values.length > 0 &&
          this.aspect.crafts &&
          this.aspect.crafts.length > 0
        ) {
          this.craftValues = values;
        }
      }
    );

    this.emitter.on("show_aspect_form", this.showAspectForm);
    this.emitter.on("show_aspect_form_by_url", this.showAspectFormByUrl);
  },
};
</script>
