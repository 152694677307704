<template>
  <section class="test-card">
    <!-- Quiz-lectures -->
    <header class="test-card__header">
      <div class="test-card__descr">
        <span class="test-card__descr-item">
          Вопрос {{ currentQuestionPos }} из
          {{ this.assessment.sample_size || questions_count }}
        </span>
        <span v-if="quizTimeLeft" class="test-card__descr-item">
          <span class="green-font-color bold-font">{{ Math.round(quizTimeLeft / 60) }} минут</span>
          до конца теста
        </span>
        <span
          class="test-card__descr-item fake-link"
          v-on:click="toggleHelpBox()"
          v-if="currentQuestion.help_message"
        >
          <img :src="'/assets/icons-v2/quiz/help-icon.svg'" alt="" />&nbsp;Подсказка
        </span>
      </div>
    </header>
    <div class="test-card__main">
      <div class="test-card__section">
        <div
          v-if="currentQuestion.help_message && isHelpVisible"
          class="inline-note inline-note--round mb-20"
        >
          <p>
            <span class="bold-font">Подсказка:</span
            >{{ currentQuestion.help_message }}
          </p>
        </div>

        <div
          v-if="this.currentQuestion.time_limit_sec > 0"
          class="inline-note inline-note--round inline-note--icon inline-note--time mb-20"
        >
          <p>
            На этот вопрос есть ограничение по времени:
            <span class="green-font-color j-question-countdown">
              {{ questionTimeLeft }}
            </span>
            сек. осталось
          </p>
        </div>

        <h2 class="test-card__subtitle">{{ currentQuestion.body }}</h2>
        <img
          v-for="img in currentQuestion.files"
          :src="img.file_url"
          class="test-card__image"
          alt=""
        />
        <component
          v-bind:is="currentQuestionType()"
          :question="currentQuestion"
          v-on:set-answer="setAnswer"
        ></component>
      </div>

      <footer class="test-card__footer">
        <a
          v-if="!loading"
          :class="[
            isNextSlideAvailable ? '' : 'disabled-btn',
            'large-btn action-btn j-test-answer-btn',
          ]"
          v-on:click="saveAnswer(currentAnswer)"
        >
          Ответить
        </a>
      </footer>
      <div
        class="time-out"
        v-if="this.assessment.time_limit > 0 && this.timeLeft < 0"
      >
        <p class="time-out__text">
          К сожалению, вы не уложились в&nbsp;отведенное время<br />
          <a v-on:click="locationReload"
            >Перейти на&nbsp;страницу результатов</a
          >
        </p>
      </div>
    </div>
    <div v-if="loading" class="loader loader--block">
      <div class="loader__icon"></div>
    </div>
  </section>

  <div v-if="started" class="modal j-popup showing">
    <div
      class="modal__card modal__card--small modal__card--centred modal__card--blank"
    >
      <h3 class="modal__subtitle">
        Продолжить с вопроса, на котором вы&nbsp;остановились?
      </h3>
      <p class="modal__text">
        Вы уже начинали проходить «{{ course_name }}». <span v-if="!single_attempt"> Если это было давно,
        рекомендуем начать заново. </span>
      </p>
      <div class="modal__footer">
        <button
          class="regular-btn action-btn mr-10 j-hide-popup"
          @click="started = false"
          type="button"
        >
          Продолжить
        </button>
        <button
          v-if="!single_attempt"
          class="regular-btn white-btn"
          v-on:click="startover"
          type="button"
        >
          Начать заново
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import QuizQuestionRadio from './quizQuestionTypes/radio.vue';
import QuizQuestionCheckbox from './quizQuestionTypes/checkbox.vue';
import QuizQuestionSorting from './quizQuestionTypes/sorting.vue';
import QuizQuestionComparison from './quizQuestionTypes/comparison.vue';
import QuizQuestionFilling from './quizQuestionTypes/flling.vue';
import QuizQuestionPicture from './quizQuestionTypes/picture.vue';
import axios from 'axios';

var globalQuestionTypes = {
  one_of_many: 'QuizQuestionRadio',
  required_all_right_answers: 'QuizQuestionCheckbox',
  ranking: 'QuizQuestionSorting',
  comparison: 'QuizQuestionComparison',
  filling: 'QuizQuestionFilling',
  picture: 'QuizQuestionPicture',
};

export default {
  name: 'LecturesQuiz',

  components: {
    QuizQuestionRadio,
    QuizQuestionCheckbox,
    QuizQuestionSorting,
    QuizQuestionComparison,
    QuizQuestionFilling,
    QuizQuestionPicture,
  },

  props: {
    assessment: Object,
    questions: Array,
    record: Object,
    choose_url: String,
    next_question_url: String,
    seconds_left: Number,
    questions_count: Number,
    answers_count: Number,
    startover_url: String,
    course_name: String,
    single_attempt: Boolean
  },

  data() {
    return {
      loading: true,
      started: this.answers_count > 0,
      timeLeft: this.seconds_left,
      currentQuestionPos: this.answers_count + 1,
      currentQuestion: {},
      currentAnswer: {},
      isHelpVisible: false,
      isNextSlideAvailable: false,
      quizSettings: {
        time: 1800,
      },
      questionsList: [],
    };
  },

  watch: {
    timeLeft(val, val2) {
      if (this.currentQuestion.time_limit_sec == 0) {
        this.saveAnswer(this.currentAnswer);
      }
    },
  },

  computed: {
    quizTimeLeft() {
      if (this.timeLeft > 0) {
        return this.timeLeft;
      }
    },
    questionTimeLeft() {
      if (this.currentQuestion.time_limit_sec > 0) {
        return this.currentQuestion.time_limit_sec;
      }
    },
  },

  mounted() {
    this.questionsList = this.getQuestions();
    this.getAssessmentQuestions();
    setInterval(this.secUpdate, 1000);
  },

  methods: {
    locationReload() {
      location.reload();
    },

    // isNextSlideAvailable() {
    //   return true
    // },

    secUpdate() {
      this.assessment.time_limit;
      this.currentQuestion.time_limit_sec--;
      this.timeLeft--;
    },

    currentQuestionType() {
      return globalQuestionTypes[this.currentQuestion.question_type];
    },

    nextSlide() {
      this.currentQuestion = {};
      setTimeout(() => {
        this.getAssessmentQuestions();
      }, 500);
      this.currentAnswer = {};
      this.isNextSlideAvailable = false;
      this.currentQuestionPos = this.currentQuestionPos + 1;
    },

    getAssessmentQuestions() {
      this.question = {};
      axios
        .request({
          response_type: "json",
          headers: { "X-Requested-With": "XMLHttpRequest" },
          url: this.next_question_url,
          method: "GET",
        })
        .then((response) => {
          if (response.data.success) {
            location.reload();
          }
          this.currentQuestion = JSON.parse(response.data.question);
          this.loading = false;
        });
    },

    applySlide(slide) {
      this.currentQuestion = this.questionsList[slide - 1];
    },

    toggleHelpBox() {
      this.isHelpVisible = this.isHelpVisible ? false : true;
    },

    setAnswer(value) {
      this.isNextSlideAvailable = true;
      this.currentAnswer = value;
    },

    startover() {
      axios
        .request({
          responseType: "json",
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            "X-CSRF-Token": document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute("content"),
          },
          url: this.startover_url,
          method: "POST",
        })
        .then((response) => {
          location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    saveAnswer(val) {
      this.loading = true;
      axios
        .request({
          responseType: "json",
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            "X-CSRF-Token": document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute("content"),
          },
          url: this.choose_url,
          method: "POST",
          data: {
            question: this.currentQuestion.id,
            answers: val,
          },
        })
        .then((response) => {
          this.nextSlide();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getQuestions() {
      return this.questions;
    },
  }
};
</script>
