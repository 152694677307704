<template>
  <section class="modal__section">
    <p class="label mb-10">{{ t.choose_an_image }}</p>
    <div class="upload-files">
      <span class="upload-files__label icon icon--regular icon--plus-action">
        <span class="visually-hidden">{{ t.upload_image }}</span>
      </span>
      <input
        type="file"
        class="upload-files__input"
        @change="changeImage"
      >
    </div>
  </section>
  <template  v-if="image != ''">
    <section class="modal__section modal__section--dashed">
      <p class="label">{{ t.select_all_areas }}</p>
      <div class="quiz-picture-wrap">
        <div class="quiz-picture">
          <img
            :src="image"
            class="quiz-picture__image"
          />
          <svg
            class="quiz-picture__svg"
            id="quizSVGImage"
            @mousedown="startDraw"
            @mousemove="dragShape"
            @mouseup="stopDrag"
          >
            <circle
              :cx="circle.x"
              :cy="circle.y"
              :r="circle.r"
              :fill="circle.color"
              opacity="0.5"
              v-for="circle in rightShapes.filter(x => x.type == 'circle')"
            />
            <rect
              :x="rect.x"
              :y="rect.y"
              :width="rect.width"
              :height="rect.height"
              :fill="rect.color"
              opacity="0.5"
              v-for="rect in rightShapes.filter(x => x.type == 'rect')"
            />
          </svg>
        </div>
      </div>

      <div class="modal__row mt-20">
        <div class="modal__col modal__col--main">
          <label class="radio-button radio-button--inline mr-20">
            <input
              class="radio-button__input"
              checked="checked"
              id="quiz_draw_circle"
              name="quizDrawFigure"
              type="radio"
              value="circle"
              v-model="currentShape"
            />
            <span class="radio-button__label">
              {{ t.round_shape }}
            </span>
          </label>
          <label class="radio-button radio-button--inline">
            <input
              class="radio-button__input"
              id="quiz_draw_rect"
              name="quizDrawFigure"
              type="radio"
              value="rect"
              v-model="currentShape"
            />
            <span class="radio-button__label">
              {{ t.rectangular_shape }}
            </span>
          </label>
        </div>
        <div class="modal__col modal__col--submain">
          <div class="inline-note inline-note--round">
            <p>{{ t.click_and_drag }}</p>
          </div>
        </div>
      </div>
    </section>
    <section class="modal__section">
      <h4 v-if="rightShapes.length" class="modal__subtitle">{{ t.selected_areas }}</h4>
      <ul class="quiz-picture-list mt-10">
        <li v-for="(answer, index) in this.answers">
          <p class="bold-font">{{ answer.picture_shapes.type == 'circle' ? 'Круг ' + (index+1) : 'Прямоугольник ' + (index+1)}}</p>
          <button class="remove-btn remove-btn--red" type="button" @click="answers.splice(index, 1); rightShapes.splice(index, 1);"></button>
        </li>
      </ul>
    </section>
  </template>
</template>

<script>
import axios from 'axios'

export default {
  name: 'QuizPictureAnswer',

  props: {
    quiz_answers: Array,
    i18n: { type: Object, required: true }
  },

  data() {
    return {
      answers: this.quiz_answers.sort((a, b) => a.pos - b.pos),
      selectedFile: null,
      name: '',
      image: this.$parent.question.picture_url,
      rightShapes: [],
      isDragging: false,
      currentShapePoint: [],
      currentShape: 'circle',
      numOfAnswers: 'one',
      t: this.i18n,
    }
  },

  created() {
    console.log('QuizPictureAnswer mounted')
    this.resetShapes();
  },

  methods: {
    changeImage(e) {
      this.$parent.$parent.loadingOn()
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.image = URL.createObjectURL(files[0])
      this.selectedFile = event.target.files[0]
      const formData = new FormData()
      formData.append('picture', this.selectedFile, this.selectedFile.name)
      formData.append('utf8', this.utf8)
      formData.append('authenticity_token', this.authenticity_token)
      axios(
        {
        response_type: 'json',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        method: this.$parent.form_action.method,
        url: this.$parent.form_action.url,
        data: formData,
      }).then((response) => {
        this.image = response.data.normal_picture_url
        this.$parent.$parent.loadingOff()
      }).catch((errors) => {
        this.$parent.$parent.loadingOff()
      })
    },

    resetShapes() {
      this.rightShapes = []
      let root = this
      this.answers = this.quiz_answers.sort((a, b) => a.pos - b.pos)
      this.answers.map(function(a) {
        root.rightShapes.push(a.picture_shapes)
      });
    },

    onFileChanged (event) {
      this.selectedFile = event.target.files[0]
      const formData = new FormData()
      formData.append('picture', this.selectedFile, this.selectedFile.name)
      axios.put('/ru/editor/courses/5655/lectures/6857/assessments/2957/questions/5326', formData)
    },

    onUpload() {
      const formData = new FormData()
      formData.append('picture', this.selectedFile, this.selectedFile.name)
      axios.post('/ru/editor/courses/5655/lectures/6857/assessments/2957/questions/5326', formData)
    },

    startDraw(e) {
      if (this.isDragging != true) {
        var rect = document.getElementById('quizSVGImage').getBoundingClientRect();
        var dotPositionY = e.clientY - rect.top
        var dotPositionX = e.clientX - rect.left
        var answer = {
          "body": null,
          "correct": null,
          "pos": this.answers.length+1,
          "secondary_body":null,
          "picture_shapes":null
        }
        var shape
        if (this.currentShape == 'circle') {
          shape = {
            type: 'circle',
            x: dotPositionX,
            y: dotPositionY,
            r: 0,
            color: '#2ff8fc'
          }
        }
        else {
          shape = {
            type: 'rect',
            x: dotPositionX,
            y: dotPositionY,
            width: 0,
            height: 0,
            color: '#2ff8fc'
          }
        }
        this.rightShapes.push(shape)
        answer.picture_shapes = shape
        answer.body = '???'
        this.answers.push(answer)
        this.currentShapePoint = [dotPositionX, dotPositionY]
        this.isDragging = true
      }
    },

    dragShape(e) {
      if (this.isDragging == true) {
        var rect = document.getElementById('quizSVGImage').getBoundingClientRect();
        var dotPositionY = e.clientY - rect.top
        var dotPositionX = e.clientX - rect.left
        if (this.currentShape == 'circle') {
          var r = this.getCircleRadius([dotPositionX, dotPositionY])
          var lastCircle = this.rightShapes[this.rightShapes.length - 1]
          lastCircle.r = r
        }
        else {
          var width = Math.max(0, dotPositionX - this.currentShapePoint[0])
          var height = Math.max(0, dotPositionY - this.currentShapePoint[1] )
          var lastRect = this.rightShapes[this.rightShapes.length - 1]
          lastRect.width = width
          lastRect.height = height
        }
      }
    },

    stopDrag() {
      this.isDragging = false
    },

    getCircleRadius(point) {
      var x = this.currentShapePoint[0] - point[0]
      var y = this.currentShapePoint[1] - point[1]
      return Math.sqrt(x*x + y*y)
    }
  },
}
</script>
