<template>
  <li class="question-card__answer answer-field" v-if="!answer._destroy" :class="{ 'answer-field--radio': is_question_with_single_answer }">
    <input type="hidden" :name="'questionnaire[questions_attributes][' + question_index + '][answers_attributes][' + answer_index + '][_destroy]'" v-model="answer._destroy">
    <input type="hidden" :name="'questionnaire[questions_attributes][' + question_index + '][answers_attributes][' + answer_index + '][id]'" v-model="answer.id">
    <input type="hidden" :name="'questionnaire[questions_attributes][' + question_index + '][answers_attributes][' + answer_index + '][answer_type]'" v-model="answer.answer_type">
    <input type="hidden" :name="'questionnaire[questions_attributes][' + question_index + '][answers_attributes][' + answer_index + '][pos]'" v-model="answer.pos">
    <label class="answer-field__field labeled-field" :class="[{ 'labeled-field--other': isOpenAnswer(answer) } ]">
      <input
        class="labeled-field__input"
        type="text"
        :name="'questionnaire[questions_attributes][' + question_index + '][answers_attributes][' + answer_index + '][title]'"
        :placeholder="isOpenAnswer(answer) ? t.other_placeholder : t.default_placeholder + ' ' + (answer_index + 1)"
        v-model.trim="answer.title"
        :disabled="isOpenAnswer(answer)"
        @input="answerFieldUpdate(answer)"
        >
    </label>
    <button class="answer-field__remove-btn remove-btn remove-btn--red" type="button" :aria-label="t.remove_btn" @click.prevent="removeAnswer(answer)"></button>
  </li>
</template>

<script>
import axios from 'axios';
import { debounce } from 'vue-debounce';

export default {
  name: 'QuestionnairesAnswer',

  props: {
    answer: { type: Object, required: true },
    question_index: { type: Number, required: true },
    answer_index: { type: Number, required: true },
    is_question_with_single_answer: { type: Boolean, required: true, default: true }
  },

  data() {
    return {
      state: '',
      t: I18n.t.questionnaire_dashboard_form.fields.questionnaire.questions.answers
    }
  },

  methods: {
    isOpenAnswer(answer) {
      return answer.answer_type == 'open';
    },

    answerFieldUpdate(answer) {
      this.state = 'modified';
      this.debouncedAnswerUpdate(answer);
    },

    debouncedAnswerUpdate: debounce(function(answer) {
      this.updateAnswer(answer);
    }, 800),

    updateAnswer(answer) {
      axios({
        response_type: "json",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        method: 'PATCH',
        url: answer.update_url,
        data: { answer }
      }).then(response => {
        this.state = 'synced';
      }).catch(errors => {
        this.state = 'error';
      })
    },

    removeAnswer(answer) {
      answer._destroy = true
      axios({
        response_type: "json",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        method: 'DELETE',
        url: answer.update_url,
        data: {}
      }).then(response => {
        this.state = 'synced';
      }).catch(errors => {
        this.state = 'error';
      })
    },
  },
}
</script>

