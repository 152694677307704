<template>
  <section class="simular-courses">
    <div class="simular-courses__inner page-inner">
        <div class="simular-courses__card">
            <h2 class="simular-courses__title">{{ $t('similar_courses') }}</h2>
            <ul class="simular-courses__list">
              <li v-for="course in courses" :key="'recommended_course_' + course.id" class="simular-courses__item">
                <CoursePreview
                  :course="course"
                  :full_only="false"
                />
              </li>
            </ul>
        </div>
    </div>
    <svg class="simular-courses__bg abstracte-bg" xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 750 500" preserveAspectRatio="xMidYMin slice">
        <polygon class="abstracte-bg__3" points="276.33 0 94.29 0 165.69 77.41 276.33 0"/>
        <polygon class="abstracte-bg__2" points="94.29 0 94.29 0 0 90.69 165.69 77.41 94.29 0"/>
        <polygon class="abstracte-bg__3" points="0 0 0 90.69 94.29 0 0 0"/>
        <polygon class="abstracte-bg__4" points="444.98 0 276.33 0 276.33 0 333.89 110.04 444.98 0"/>
        <polygon class="abstracte-bg__5" points="333.89 110.04 276.33 0 276.33 0 165.69 77.41 333.89 110.04"/>
        <polygon class="abstracte-bg__4" points="333.89 110.04 165.69 77.41 223.43 216.74 333.89 110.04"/>
        <polygon class="abstracte-bg__4" points="165.69 77.41 0 90.69 75.94 179.08 165.69 77.41"/>
        <polygon class="abstracte-bg__2" points="165.69 77.41 75.94 179.08 223.43 216.74 165.69 77.41"/>
        <polygon class="abstracte-bg__3" points="0 90.69 0 248.12 75.94 179.08 0 90.69"/>
        <polygon class="abstracte-bg__3" points="75.94 179.08 101.67 288.29 223.43 216.74 75.94 179.08"/>
        <polygon class="abstracte-bg__5" points="75.94 179.08 0 248.12 101.67 288.29 75.94 179.08"/>
        <polygon class="abstracte-bg__1" points="358.68 248.12 333.89 110.04 223.43 216.74 358.68 248.12"/>
        <polygon class="abstracte-bg__2" points="444.98 0 333.89 110.04 473.43 132.36 444.98 0"/>
        <polygon class="abstracte-bg__5" points="473.43 132.36 333.89 110.04 358.68 248.12 473.43 132.36"/>
        <polygon class="abstracte-bg__3" points="223.43 216.74 272.38 318.41 358.68 248.12 223.43 216.74"/>
        <polygon class="abstracte-bg__1" points="101.67 288.29 272.38 318.41 223.43 216.74 101.67 288.29"/>
        <polygon class="abstracte-bg__1" points="601.25 0 444.98 0 473.43 132.36 601.25 0"/>
        <polygon class="abstracte-bg__5" points="643.25 147.07 601.25 0 473.43 132.36 643.25 147.07"/>
        <polygon class="abstracte-bg__3" points="358.68 248.12 523.12 303.35 473.43 132.36 358.68 248.12"/>
        <polygon class="abstracte-bg__1" points="523.12 303.35 643.25 147.07 473.43 132.36 523.12 303.35"/>
        <polygon class="abstracte-bg__2" points="272.38 318.41 392.89 389.96 358.68 248.12 272.38 318.41"/>
        <polygon class="abstracte-bg__1" points="358.68 248.12 392.89 389.96 523.12 303.35 358.68 248.12"/>
        <polygon class="abstracte-bg__2" points="165.69 416.32 272.38 318.41 101.67 288.29 165.69 416.32"/>
        <polygon class="abstracte-bg__4" points="0 248.12 0 406.28 101.67 288.29 0 248.12"/>
        <polygon class="abstracte-bg__1" points="101.67 288.29 0 406.28 165.69 416.32 101.67 288.29"/>
        <polygon class="abstracte-bg__3" points="0 406.28 82.84 500 165.69 416.32 0 406.28"/>
        <polygon class="abstracte-bg__5" points="0 500 82.84 500 0 406.28 0 500"/>
        <polygon class="abstracte-bg__5" points="82.84 500 249.79 500 165.69 416.32 82.84 500"/>
        <polygon class="abstracte-bg__3" points="165.69 416.32 249.79 500 272.38 318.41 165.69 416.32"/>
        <polygon class="abstracte-bg__1" points="272.38 318.41 249.79 500 392.89 389.96 272.38 318.41"/>
        <polygon class="abstracte-bg__5" points="249.79 500 434.31 500 392.89 389.96 249.79 500"/>
        <polygon class="abstracte-bg__1" points="392.89 389.96 504.6 423.85 523.12 303.35 392.89 389.96"/>
        <polygon class="abstracte-bg__3" points="392.89 389.96 434.31 500 504.6 423.85 392.89 389.96"/>
        <polygon class="abstracte-bg__4" points="523.12 303.35 652.72 319.04 643.25 147.07 523.12 303.35"/>
        <polygon class="abstracte-bg__5" points="504.6 423.85 652.72 319.04 523.12 303.35 504.6 423.85"/>
        <polygon class="abstracte-bg__4" points="434.31 500 601.25 500 504.6 423.85 434.31 500"/>
        <polygon class="abstracte-bg__2" points="504.6 423.85 601.25 500 652.72 319.04 504.6 423.85"/>
        <polygon class="abstracte-bg__2" points="643.25 147.07 750 0 750 0 601.25 0 643.25 147.07"/>
        <polygon class="abstracte-bg__1" points="750 169.66 750 0 643.25 147.07 750 169.66"/>
        <polygon class="abstracte-bg__5" points="652.72 319.04 750 253.53 750 253.53 643.25 147.07 652.72 319.04"/>
        <polygon class="abstracte-bg__3" points="750 425.47 652.72 319.04 601.25 500 750 425.47 750 425.47"/>
        <polygon class="abstracte-bg__1" points="750 425.47 750 253.53 652.72 319.04 750 425.47"/>
        <polygon class="abstracte-bg__3" points="750 253.53 750 169.66 643.25 147.07 750 253.53"/>
        <polygon class="abstracte-bg__2" points="750 425.47 601.25 500 750 500 750 474.02 750 425.47"/>
    </svg>
  </section>
</template>

<script>
import CoursePreview from "./Catalog/CoursePreview.vue";

export default {
  props: {
    courses: {
      type: Array,
      required: true
    }
  },

  components: {
    CoursePreview
  }
}
</script>
