<template>
  <div class="adm-train ">
    <div class="adm-train__aside relative">
        <div v-if="this.loadingOn" class="loader loader--block">
            <div class="loader__icon"></div>
        </div>
        <div v-if="currentContent.id" class="adm-train__aside-holder adm-train__aside-holder--header">
            <div class="sub-title-font-size bold-font medium-line-height">Выполнено: {{ result.count_completed_contents }} из {{ result.count_contents }}</div>
            <div class="progress-scale mt-15">
                <div class="progress-scale__bar"
                    :style="{ width: result.completed_percent + '%' }"
                />
            </div>
        </div>
        <section
          v-for="category in categories"
          :key="category.id"
        >
          <div class="adm-train__aside-holder mt-20">
            <h4 class="adm-train__aside-title">
                {{ category.title }}
            </h4>
          </div>
          <ul class="adm-train__aside-list mt-10">
              <li class="adm-train__aside-item"
                  :class="{ 'adm-train__aside-item--checked': currentContent.id == content.id, 'adm-train__aside-item--done': isContentCompleted(content, result.complete_content_ids) }"
                  v-for="content in category.contents"
                  :key="content.id"
                >
                  <button @click.prevent="setCurrentContent(content)" class="adm-train__aside-btn" type="button">
                        {{ content.title }}
                  </button>
              </li>
          </ul>
        </section>
    </div>
    <div class="adm-train__main relative">
        <div v-if="this.loadingOn" class="loader loader--block">
            <div class="loader__icon"></div>
        </div>
        <template v-if="currentContent.id">
            <div class="adm-train__main-holder adm-train__main-holder--header">
                <h1 class="title-font-size bold-font medium-line-height">{{ guide.title }}</h1>
                <div class="longread longread--no-pads mt-20">{{ guide.description }}</div>
            </div>
            <div class="adm-train__main-holder longread" v-html="currentContent.body" />
            <div class="adm-train__main-confirm mt-25" :class="{ 'adm-train__main-confirm--checked': isContentCompleted(currentContent, result.complete_content_ids) }">
                <button class="adm-train__main-btn regular-btn white-btn" type="button"
                @click.prev="completeContent(currentContent)"
                :disabled="isContentCompleted(currentContent, result.complete_content_ids)"
                >
                    Я знаю, как это делать
                </button>
            </div>
        </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Guide',

  data() {
      return {
          guide: Object,
          categories: Array,
          result: Object,
          currentContent: Object,
          loadingOn: true
      }
  },

  props: {
    guide_url: { type: String, require: true }
  },

  methods: {
      getGuideUrl() {
        return document.querySelector('#guide').dataset.guideUrl;
      },

      setCurrentContent(content) {
          this.currentContent = content;
      },

      completeContent(content) {
          axios({
              response_type: 'json',
              headers: {
                  'X-Requested-With': 'XMLHttpRequest',
                  'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
              },
              method: 'POST',
              url: content.guide_complete_content_url
          }).then((response) => {
              this.result = response.data.result
              this.currentContent = this.result.current_content
              window.scrollTo({ top: 100, left: 100, behavior: 'smooth' })
          })
      },

      isContentCompleted(content, completedIds) {
          return completedIds.includes(content.id);
      }
  },

  mounted() {
      this.loadingOn = true;

      axios({
          response_type: 'json',
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
          method: 'GET',
          url: this.guide_url,
      }).then((response) => {
          this.guide = response.data.guide
          this.categories = response.data.categories
          this.result = response.data.result
          this.currentContent = this.result.current_content
          this.loadingOn = false
      })
  },
}
</script>
