<template>
	<div class="catalog">
		<div class="catalog__header catalog__header--bg-action">
			<template v-if="recommended">
				<h1 v-if="current_company === 'megafon'" class="catalog__title">
					{{ $t('recommended_megafon_plan') }}
				</h1>
				<h1 v-else class="catalog__title">
					{{ $t('recommended_programms') }}
				</h1>
			</template>
			<template v-else>
				<h1 v-if="current_company === 'megafon'" class="catalog__title">
					{{ $t('megafon_plan') }}
				</h1>
				<h1 v-else class="catalog__title">
					{{ $t('learning_plan') }}
				</h1>
			</template>
		</div>

		<section class="catalog__section">
			<template v-if="filteredSelection(selection).length">
				<div class="catalog__section-header catalog__section-header--with-link">
					<template v-if="recommended">
						<h2 v-if="current_company === 'megafon'" class="catalog__title">
							{{ $t('buttons.prescription_all') }}
							<span class="quantity">{{ filteredSelection(selection).length }}</span>
						</h2>
						<h2 v-else class="catalog__title">
							{{ $t('buttons.all_recommended_programms') }}
							<span class="quantity">{{ filteredSelection(selection).length }}</span>
						</h2>
					</template>
					<template v-else>
						<h2 v-if="current_company === 'megafon'" class="catalog__title">
							{{ $t('buttons.prescription_all') }}
							<span class="quantity">{{ filteredSelection(selection).length }}</span>
						</h2>
						<h2 v-else class="catalog__title">
							{{ $t('buttons.all_required_programms') }}
							<span class="quantity">{{ filteredSelection(selection).length }}</span>
						</h2>
					</template>

					<p class="catalog__right">
						<button @click="uncompletedOnly = !uncompletedOnly" class="link-btn link-btn--light-font">
							{{ uncompletedOnly ? $t('buttons.show_completed') : $t('buttons.hide_completed') }}
						</button>
					</p>
				</div>
				<div class="catalog__list">
					<div
						v-for="(prescription, index) in filteredSelection(selection)"
						:key="'section-' + prescription.id + '-' + index"
						class="catalog__item"
					>
					<PlanPreview
						:prescription="prescription"
						:currentCompany="current_company"
						:recommended="recommended"
					/>
					</div>
				</div>
			</template>
			<template v-else>
				<template v-if="recommended">
					<div v-if="current_company === 'megafon'" class="page-card page-card--bordered message-card message-card--inline message-card--with-centred-icon message-card--empty-learning-plan">
						<p class="message-card__label">{{ $t('messages.recommended_megafon_1') }}</p>
						<p class="message-card__title">{{ $t('messages.recommended_megafon_2') }}</p>
					</div>
					<div v-else class="page-card page-card--bordered message-card message-card--inline message-card--with-centred-icon message-card--empty-learning-plan">
						<p class="message-card__label">{{ $t('messages.no_recommended_training') }}</p>
						<p class="message-card__title">{{ $t('messages.choose_description') }}</p>
						<a href="/courses" class="regular-btn action-btn">
							{{ $t('buttons.choose_course') }}
						</a>
					</div>
				</template>
				<template v-else>
					<div v-if="current_company === 'megafon'" class="page-card page-card--bordered message-card message-card--inline message-card--with-centred-icon message-card--empty-learning-plan">
						<p class="message-card__label">{{ $t('messages.megafon_1') }}</p>
						<p class="message-card__title">{{ $t('messages.megafon_2') }}</p>
					</div>
					<div v-else class="page-card page-card--bordered message-card message-card--inline message-card--with-centred-icon message-card--empty-learning-plan">
						<p class="message-card__label">{{ $t('messages.no_training') }}</p>
						<p class="message-card__title">{{ $t('messages.choose_description') }}</p>
						<a href="/courses" class="regular-btn action-btn">
							{{ $t('buttons.choose_course') }}
						</a>
					</div>
				</template>
			</template>
		</section>
	</div>
</template>

<script>
import PlanPreview from "./PlanPreview.vue";

export default {
	name: "PlanTopic",

	components: {
		PlanPreview
	},

	props: {
		selection: Array,
		learning_plan_result: String,
		newCourses: {
			type: Boolean,
			default: false,
		},
		current_company: {
			type: String,
			default: ''
		},
		recommended: {
			type: Boolean,
			default: false
		},
	},

	data() {
		return {
			uncompletedOnly: false,
			loading: true,
			isContinue: false,
		};
	},

	methods: {
		filteredSelection(selection) {
			return this.uncompletedOnly
				? selection.filter((prescription) => prescription.result != 100)
				: selection;
		},
	}
}
</script>
