<template>
  <div class="modal__main">
    <div class="items-in-set items-in-set--medium scrollable-shadow">
      <template v-for="preset in presetItems" :key="preset.id">
        <div
          v-if="preset.tasks.length"
          class="assignment-preset"
          @click.stop="selectPreset(preset.id)"
        >
          <div class="assignment-preset__header" :class="{ 'is-selected': isSelected(preset.id) }" tabindex="0">
              <h3 class="assignment-preset__title">{{ preset.name }}</h3>
              <div class="assignment-preset__descr">
                {{ preset.count_tasks }} · {{ preset.duration }}
              </div>
              <button
                class="assignment-preset__btn small-btn white-btn"
                type="button"
                @click.stop="toggleOpened(preset.id)"
                v-text="preset.id == openedPreset ? t.btns.hide : t.btns.open"
              />
          </div>
          <ul class="assignment-preset__list" v-show="preset.id == openedPreset">
            <li v-for="(task, index) in preset.tasks" :key="task.gid" class="assignment-preset__item">
              <h4 class="assignment-preset__subtitle">
                {{ task.name }}
                <span v-if="parseFloat(task.rating) > 0">
                  ★{{ task.rating }}
                </span>
              </h4>
              <div class="assignment-preset__descr">
                {{ task.type }} · {{ task.hours }}
              </div>
            </li>
          </ul>
        </div>
      </template>
    </div>
    <p v-if="selectedPreset" class="modal__text text-center mt-15">
      Вы выбрали: «{{ currentPreset.name }}»
    </p>

    <div class="modal__footer">
      <button
        class="regular-btn action-btn mr-5"
        @click="sendTasksOnAssignmentForm"
        v-text="t.btns.use_sets"
      />
      <button
        class="regular-btn white-btn"
        @click="closePresetsPopup"
        v-text="t.btns.cancel"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ProgramPresetsForm",

  props: {
    url: String
  },

  data() {
    return {
      presetItems: null,
      openedPreset: null,
      selectedPreset: null,
      t: I18n.t
    };
  },

  computed: {
    currentPreset() {
      return this.presetItems.find(x => x.id == parseInt(this.selectedPreset));
    },

    currentTasks() {
      let result = [];
      for (const task of this.currentPreset.tasks) {
        result.push(task.gid);
      }
      return result;
    }
  },

  mounted() {
    axios
      .request({
        response_type: "json",
        headers: { "X-Requested-With": "XMLHttpRequest" },
        url: this.url
      })
      .then(response => {
        this.presetItems = response.data;
      });

    this.emitter.on("show_presets_popup", this.showPresetsPopup);
  },

  methods: {
    toggleOpened(id) {
      this.openedPreset = this.openedPreset == id ? null : id;
    },
    isSelected(id) {
      return this.selectedPreset == id;
    },
    selectPreset(id) {
      this.selectedPreset = id;
    },

    showPresetsPopup() {
      this.clearPresetsForm();
      this.$parent.openPopup();
    },

    closePresetsPopup() {
      this.$parent.closePopup();
    },

    clearPresetsForm() {
      this.selectedPreset = null;
      this.openedPreset = null;
      return this;
    },

    sendTasksOnAssignmentForm() {
      console.log(this.currentTasks)
      if (this.selectedPreset) {
        this.emitter.emit("tasks_for_assignment_form", { name: "dropdownTaskable", values: this.currentTasks});
        this.emitter.emit("name_for_assignment_form", this.currentPreset.name);
        this.emitter.emit("id_for_assignment_form", this.selectedPreset);
        this.clearPresetsForm().closePresetsPopup();
      }
    }
  },
};
</script>
