<template>
  <div class="timecode">
      <div class="timecode__header">
        <h2 class="timecode__header-title">{{ $t('timecodes') }}</h2>
      </div>
      <div class="timecode__content">
          <ol class="timecode__list">
            <li v-for="chapter in chaptersData" class="timecode__item">
              <a class="timecode__link" @click="rewindVideoTo(chapter)">
                <span class="timecode__descr" v-html="chapter.name"></span>
                <span class="timecode__time">{{chapter.time_human}}</span>
              </a>
            </li>
          </ol>
      </div>
    </div>
</template>

<script>
export default {
  name: "TimeCode",

  props: {
    chapters: Array,
  },

  data() {
    return {
      chaptersData: this.chapters,
      player: window.player,
    };
  },

  created: function () {
    player.markers({
      markers: this.chaptersData.map(chapter => Object.create({ time: chapter.time_in_sec, text: chapter.name }))
    });
  },

  methods: {
    rewindVideoTo: function(chapter) {
      let stub = document.getElementsByClassName('j-hide-video-stub')[0]
      if (!stub.classList.contains('not-showing'))
        stub.classList.add('not-showing')
        setTimeout(function(){
          stub.classList.add("hide")
        }, 600);

      player.currentTime(chapter.time_in_sec)
      player.play()
    },
  },
};
</script>
