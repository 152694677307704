<template>
  <div class="rbc-content rbc-content--start">
    <div class="rbc-inner">
      <div class="rbc-aside">
        <nav class="rbc-nav">
          <div class="mb-30">
            <h2 class="rbc-nav__title">Фильтры</h2>
            <ul>
              <li
                 v-for="(filter, index) in courseTypesFilter"
                :key="'filter-' + index"
                class="mb-7"
              >
                <label class="checkbox checkbox--small">
                  <input
                    type="checkbox"
                    class="visually-hidden"
                    :value="filter.type"
                    name
                    v-model="checkedTypesFilterRbcStart"
                  />
                  <span class="checkbox__label">
                    {{ t[filter.type] }}
                  </span>
                </label>
              </li>
            </ul>
            <button @click="filterClear" class="regular-btn action-btn mt-15" :class="{ 'disabled': !checkedTypesFilterRbcStart.length }">
              {{ t.filter_clear }}
            </button>
          </div>

          <h2 class="rbc-nav__title">Тематики</h2>
          <ol class="rbc-nav__lvl-1">
            <template v-for="(career, index) in prescription_items" :key="'career-' + career.id">
              <li v-show="showFilteredCareers(career)">
                <h3>
                  <a :href="'#career_id_' + career.id" @click.prevent="scrollTo">{{ career.name }}</a>
                </h3>
                <ol class="rbc-nav__lvl-2">
                    <template
                      v-for="(aspect, index) in career.aspects"
                      :key="'career-aspect' + index"
                    >
                      <li v-show="showFilteredCrafts(aspect.crafts)">
                        <a :href="'#aspect_id_' + aspect.id" @click.prevent="scrollTo">{{ aspect.name }}</a>
                      </li>
                    </template>
                </ol>
              </li>
            </template>
          </ol>
        </nav>
      </div>

      <main class="rbc-main">
        <div class="rbc-bordered">
          <h1 class="rbc-title rbc-col">
            Опция Eduson от РБК Pro
          </h1>

          <div class="rbc-text rbc-col">
            <p>
              Опция Eduson от&nbsp;РБК Pro дает вам доступ к&nbsp;библиотеке видеокурсов с&nbsp;экспертами и&nbsp;интерактивным форматам обучения для
              закрепления знаний на&nbsp;практике по&nbsp;12 темам: менеджмент, проектное управление, маркетинг, продажи и&nbsp;клиентский сервис,
              HR, финансы, логистика, закупки, маркетплейсы, переговоры и&nbsp;публичные выступления, <nobr>тайм-менеджмент</nobr>,
              программы и&nbsp;инструменты.
            </p>

            <p>
              Видеокурсы и&nbsp;<nobr>мини-курсы</nobr> помогут погрузиться вам в&nbsp;тему или систематизировать знания, а&nbsp;интерактивные форматы
              (тренажеры, <nobr>бизнес-кейсы</nobr>, тесты и&nbsp;презентации)&nbsp;&mdash; отработать навыки и&nbsp;закрепить изученное.
            </p>
          </div>

          <label class="checkbox">
            <input  type="checkbox" v-model="isOpenInfoRbcStart">
            <span class="link-btn">
              <template v-if="isOpenInfoRbcStart">− Скрыть информационные блоки</template>
              <template v-else>+ Показать информационные блоки</template>
            </span>
          </label>

          <div v-if="isOpenInfoRbcStart" class="rbc-stat rbc-stat--start mt-10">
            <div class="rbc-stat__item">
              <h3>Как устроено обучение</h3>
              <p>
                <nobr>Эксперты-практики</nobr> из&nbsp;Сбербанка, Ozon, Сколково поделятся лайфхаками и&nbsp;личными кейсами.
                Интерактивные тренажеры научат оценивать риски, принимать решения на&nbsp;смоделированных ситуациях,
				а&nbsp;также помогут закрепить новые навыки. Мы&nbsp;используем игровой подход и&nbsp;технологии AR, чтобы учиться было интересно.
              </p>
            </div>

            <div class="rbc-stat__item">
              <h3><span class="icon icon--box icon--regular icon--info-main mr-5"></span>Почему Eduson</h3>
              <p>
                С&nbsp;2013 года более 223 тысяч специалистов повысили компетенции и&nbsp;усилили позиции бизнеса благодаря
                курсам Eduson. Нам доверили обучение сотрудников Ростелеком, <nobr>Mercedes-Benz</nobr>, Газпромбанк или
                X5 Retail Group и&nbsp;другие компании. Опытная команда методистов, продюсеров и&nbsp;аналитиков
                ежемесячно пополняет библиотеку курсов новым контентом и&nbsp;следит за&nbsp;качеством материалов.
              </p>
            </div>

            <div class="rbc-stat__item">
              <h3 class="mb-3">Как начать обучение</h3>
              <p>
                Прочитайте руководство, чтобы начать пользоваться платформой Eduson. Здесь вы&nbsp;познакомитесь с&nbsp;навигацией,
                научитесь открывать для себя новые курсы и&nbsp;найдете контакты поддержки для решения любых вопросов.
                До&nbsp;скорых встреч!
              </p>
              <p class="mt-auto">
                <a class="small-btn action-btn" target="_blank" href="https://downloads.eduson.tv/materials/files/000/004/840/original/PresentationRBCPro.pdf">
                  Скачать
                </a>
              </p>
            </div>
          </div>
        </div>
        <template
          v-for="(career, index) in prescription_items"
          :key="'career-' + career.id"
        >
          <section
            v-if="showFilteredCareers(career)"
            :id="'career_id_' + career.id"
            class="rbc-section rbc-bordered"
          >
            <div class="rbc-col">
              <h2 class="rbc-section__title">
                {{ career.name }}
              </h2>
              <div class="rbc-text">
                {{ career.description }}
              </div>
            </div>
            <template
              v-for="(aspect, index) in career.aspects"
              :key="'career-aspect-' + aspect.id"
            >
              <section
                v-if="showFilteredCrafts(aspect.crafts)"
                :id="'aspect_id_' + aspect.id"
                class="rbc-item"
              >
                <div class="rbc-col">
                  <h3 class="rbc-item__title">
                    <span class="rbc-item__title-icon">
                      <img
                          :src="aspect.icon_url"
                          :alt="aspect.name"
                          loading="lazy"
                      />
                    </span>
                    {{ aspect.name }}
                  </h3>

                  <div v-if="aspect.description" class="rbc-text">
                    {{ aspect.description }}
                  </div>
                </div>

                <ol
                  class="rbk-cards-list"
                >
                  <li
                    v-for="(craft, index) in filteredCrafts(aspect.crafts)"
                    :key="index"
                  >
                    <a :href="'/courses/' + craft.course.id"
                      class="rbc-card"
                    >
                      <img
                          :src="craft.course_logo"
                          alt=""
                          class="rbc-card__image"
                          loading="lazy"
                      />
                      <div class="rbc-card__holder">
                        <div class="rbc-card__info mb-5">
                          {{ t[craft.course.course_type] }}
                        </div>

                        <h3 class="rbc-card__title" v-html="craft.name"></h3>

                        <div class="rbc-card__info mt-5">
                          <template v-if="showLecturesInfo(craft.course.course_type)">
                            {{ showLectures(craft.course.lectures.length ) }} ·
                          </template>

                          <template v-if="craft.course.with_diploma">
                            Диплом ·
                          </template>

                          {{ showDuration(craft.course.duration_minutes) }}
                        </div>
                      </div>
                    </a>
                  </li>
                </ol>
              </section>
            </template>
          </section>
        </template>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: "RbcStart",

  props: {
    prescription_items: {
      type: Array,
      required: true
    },
  },

  data() {
    return {
      loading: true,
      isOpenInfoRbcStart: true,
      t: I18n.t.courses,
      checkedTypesFilterRbcStart: [],
      courseTypesFilter: [
        { title: "Видеокурсы", type: "long" },
        { title: "Мини-курсы", type: "short" },
        { title: "Инт. презентация", type: "presentation" },
        { title: "Бизнес-кейсы", type: "business_case" },
        { title: "Тренажер", type: "audition" },
        { title: "Практическое задание", type: "practical_task" }
      ],
    };
  },

  mounted() {
    if (localStorage.isOpenInfoRbcStart) {
      this.isOpenInfoRbcStart = localStorage.isOpenInfoRbcStart === 'true';
    }

    if (localStorage.checkedTypesFilterRbcStart) {
      this.checkedTypesFilterRbcStart = localStorage.checkedTypesFilterRbcStart.split(',');
    }
  },

  watch: {
    isOpenInfoRbcStart(newValue) {
      localStorage.isOpenInfoRbcStart = newValue;
    },

    checkedTypesFilterRbcStart(newValue) {
      localStorage.checkedTypesFilterRbcStart = newValue;
    }
  },

  methods: {
    filteredCrafts(crafts) {
      return this.checkedTypesFilterRbcStart.length != 0
        ? crafts.filter((craft) =>
            this.checkedTypesFilterRbcStart.includes(craft.course.course_type)
          )
        : crafts;
    },

    scrollTo(e) {
      let el = document.querySelector(e.target.getAttribute('href'));
      let heightScroll = el.getBoundingClientRect().top + window.scrollY - 80;
      window.scroll({top: heightScroll, behavior: 'smooth'});
    },

    showLectures(length) {
      if (length == 1) {
        return length + ' лекция';
      }

      if (length > 1 && length < 5) {
        return length + ' лекции';
      }

      return length + ' лекций';
    },

    showLecturesInfo(type) {
      if (type === 'business_case' || type === 'presentation' || type === 'audition' || type === 'practical_task') {
        return false;
      }
      return true;
    },

    showDuration(duration) {
      if (duration/60 == 1) {
        return '1 ч.'
      }

      if (duration/60 > 1 && duration%60 === 0 ) {
        return Math.floor(duration/60) +  ' ч.'
      }

      if (duration/60 > 1 && duration%60 !== 0 ) {
        return Math.floor(duration/60) +  ' ч. ' + duration%60 + ' мин.'
      }

      return duration + ' мин.';
    },

    filterClear() {
      this.checkedTypesFilterRbcStart = [];
    },

    showFilteredCareers(career) {
      return career.aspects.filter(aspect => this.filteredCrafts(aspect.crafts).length).length;
    },

    showFilteredCrafts(crafts) {
      return this.filteredCrafts(crafts).length;
    },
  },
};
</script>
