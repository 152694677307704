<template>
  <div class="popover popover--middle-card" :class="{ 'is-showing': popupShowing }">
    <button class="popover__btn regular-btn red-btn " :class="btnClass" @click.prev="showPopup" type="button">
      {{ btn_name }}
    </button>
    <div class="popover__panel">
      <h3 class="popover__title">{{ popup_title }}</h3>
      <p v-html="popup_description"></p>
      <div class="popover__footer">
        <slot></slot>
        <button class="small-btn white-btn" @click="hidePopup" type="button">
          <span class="red-font-color">{{ cancel_btn_name }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BtnConfirmPopup',

  props: {
    btn_name: { type: String, require: true },
    btn_class: { type: String, require: false },
    popup_title: { type: String, require: false, default: 'Attention!' },
    popup_description: { type: String, require: false },
    cancel_btn_name: { type: String, require: false, default: 'Cancel' },
    disabled: { type: Boolean, require: false, default: false }
  },

  data() {
    return {
      popupShowing: false
    }
  },

  computed: {
    btnClass() {
      return this.btn_class + ' ' + ((this.popupShowing || this.disabled) ? 'disabled-btn' : '')
    }
  },

  methods: {
    showPopup() {
      this.popupShowing = true
    },

    hidePopup() {
      this.popupShowing = false
    }
  }
}
</script>
