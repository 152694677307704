<template>
  <div :class="[{ showing: showPopup }, 'modal']" >
    <div class="modal__card modal__card--small modal__card--blank">
      <div v-if="loading" class='j-loading-action loading-white-holder'><div class='loading-sizer loading-spinner'></div></div>
      <slot></slot>
      <button
        class="regular-btn action-btn mt-20"
        :class="{ 'disabled-btn': !isDisabledBtn }"
        :disabled="!isDisabledBtn"
        @click="closePopup()"
        type="button"
      >Закрыть</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPopup',

  props: {
    withCloseBtn: false
  },

  data() {
    return {
      showPopup: false,
      loading: false,
      isDisabledBtn: this.withCloseBtn
    }
  },

  methods: {
    closePopup(event) {
      this.showPopup = false;
      return this;
    },

    openPopup() {
      this.showPopup = true;
      return this;
    },

    loadingOn() {
      this.loading = true;
      return this;
    },

    loadingOff() {
      this.loading = false;
      return this;
    },

    disabledBtn() {
      this.isDisabledBtn = false;
      return this;
    },

    unDisabledBtn() {
      this.isDisabledBtn = true;
      return this;
    }
  },
}
</script>
