<script>
export default {
  name: 'ProgramsForm',
  template: `<slot v-bind="self" />`,

  props: {
    program: { type: Object },
    assignments: { type: Array },
    new_prescription_item_url: { type: String },
    open_assignment_form: { type: Boolean },
    can_manage_accessible_input: { type: Boolean }
  },

  data() {
    return {
      programName: this.program.name,
      programNamePlaceholder: this.program.name,
      showDescription: this.program.show_description,
      showEmailDescription: this.program.show_email_description,
      accessibleToId: this.program.accessible_to_id,
      assignment_items: this.assignments,
	  recommended: this.program.recommended,
    }
  },

  computed: {
    self: function() {
      return this;
    },
  },

  mounted() {
    if (this.can_manage_accessible_input) {
      this.dropdown = new Dropdown(document.querySelector("div[data-dropdown-name='j-accessible-dropdown']"), (value) => {
        this.accessibleToId = value[0] && value[0].id;
      });
    }

    this.emitter.emit('set_root_assignment_items', this.setAssignmentItems);
    this.emitter.on('set_root_assignment_items', this.setAssignmentItems);

    if (this.open_assignment_form) this.autoOpenNewAssignmentForm();
  },

  methods: {
    showNewAssignmentForm(url) {
      this.emitter.emit('show_assignment_form', url);
    },

    setAssignmentItems(assignments) {
      console.log('send assignments')
      this.assignment_items = assignments;
    },

    autoOpenNewAssignmentForm() {
      this.$nextTick(function () {
        this.showNewAssignmentForm(this.new_prescription_item_url);
      })
    }
  }
}
</script>
