<template>
	<section class='common-banner'>
		<a :href="anchor ? '#' + anchor : null"
		   @click="handleClick"
		   class="common-banner__link">
			<picture>
				<img class="common-banner__image" :src="image_url" alt="" />
			</picture>
		</a>
	</section>
</template>

<script>
import axios from "axios";

export default {
	name: 'CommonBanner',

	props: {
		current_company: {
			type: String,
			required: true
		},
		events_url: {
			type: String,
			required: true
		},
		current_user_id: {
			type: Number,
			required: false
		},
		image_url: {
			type: String,
			required: true
		},
		redirect_url: {
			type: String,
			required: false
		},
		click_event_name: {
			type: String,
			required: false
		},
		anchor: {
			type: String,
			required: false
		}
	},

	methods: {
		handleClick() {
			if (this.click_event_name) {
				this.sendEvent();
			} else if (this.redirect_url) {
				window.location.href = this.redirect_url;
			}
		},

		sendEvent() {
			axios.request({
				headers: {
					"X-Requested-With": "XMLHttpRequest",
					"X-CSRF-Token": document
						.querySelector('meta[name="csrf-token"]')
						.getAttribute("content"),
				},
				url: this.events_url,
				method: "POST",
				data: {
					name: this.click_event_name,
					description: "Запрос из курса: " + this.current_company,
					user_id: this.current_user_id,
					eventable_type: "Company",
					eventable_id: this.current_company.id,
					redirect_url: this.redirect_url
				}
			}).then((response) => {
				window.location.href = this.redirect_url;
			})
		}
	}
}
</script>

<style>
.common-banner {
	border-radius: 16px;
}

.common-banner__link {
	display: block;
}

.common-banner__image {
	display: block;
	width: 100%;
	border-radius: 16px;
}
</style>
