<template>
  <ul class="test-card__list">
    <li  v-for="(val, key, index) in values" class="test-card__item">
      <label class="checkbox checkbox--bordered">
        <input
          type="checkbox"
          :id="question.pos + '-' + key"
          :value="val"
          v-model="checkedValues"
          v-on:change="$emit('set-answer', checkedValues)"
          class="checkbox__input"
        >
        <span :for="question.pos + '-' + key" class="checkbox__label">
          {{ val.body }}
        </span>
      </label>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'QuizQuestionCheckbox',

  props: {
    question: Object
  },

  data() {
    return {
      checkedValues: [],
      values: this.question.answers
    }
  },
}
</script>
