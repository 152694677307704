<template>
  <div class="lang-en-results">
    <div class="lang-en-results__top">
      <div class="lang-en-results__col lang-en-results__col--legend">
        <dl class="lang-en-results__legend">
          <dt class="lang-en-results__legend-name">Ваш результат</dt>
          <dd class="lang-en-results__legend-value">{{ userResults.total.score }}/100</dd>
          <dt class="lang-en-results__legend-name">Ваш уровень</dt>
          <dd class="lang-en-results__legend-value">{{ userResults.total.name }}/100</dd>
        </dl>
      </div>
      <div class="lang-en-results__col lang-en-results__col--chart">
        <svg :width="svgProps.width" :height="svgProps.height">
          <circle
            :r="svgProps.radius"
            :cx="svgProps.width / 2"
            :cy="svgProps.height / 2"
            fill="transparent"
            stroke="#EEEEEE"
            stroke-width="2"
            stroke-dasharray="8 10"
          ></circle>
          <circle
            :r="svgProps.radius - 30"
            :cx="svgProps.width / 2"
            :cy="svgProps.height / 2"
            fill="#EEEEEE"
          ></circle>

          <g fill="transparent" stroke="#5FB855" stroke-width="5">
            <path
              :d="
                describeArc(
                  svgProps.width / 2,
                  svgProps.height / 2,
                  svgProps.radius,
                  0,
                  resultAngle
                )
              "
            />
          </g>

          <template v-for="(rank, i) in rankNames" :key="'rank_' + i">
            <text
              :x="
                polarToCartesian(
                  svgProps.width / 2,
                  svgProps.height / 2,
                  svgProps.radius + 20,
                  i * 60
                ).x
              "
              :y="
                polarToCartesian(
                  svgProps.width / 2,
                  svgProps.height / 2,
                  svgProps.radius + 20,
                  i * 60
                ).y
              "
              :fill="[userRankNum < i ? '#DDDDDD' : '']"
              class="small-font-size bold-font"
              :text-anchor="textAnchor(i * 60)"
              :alignment-baseline="alignmentBaseline(i * 60)"
            >
              {{ rank }}
            </text>
            <circle
              r="10"
              :cx="
                polarToCartesian(
                  svgProps.width / 2,
                  svgProps.height / 2,
                  svgProps.radius,
                  i * 60
                ).x
              "
              :cy="
                polarToCartesian(
                  svgProps.width / 2,
                  svgProps.height / 2,
                  svgProps.radius,
                  i * 60
                ).y
              "
              :fill="userRankNum >= i ? '#5FB855' : '#FFFFFF'"
              :stroke="userRankNum >= i ? 'transparent' : '#EEEEEE'"
              stroke-width="2"
            ></circle>
          </template>
        </svg>
      </div>
    </div>
    <div class="mt-30">
      <ul class="lang-en-results__list">
        <li class="lang-en-results__item lang-en-results__item--audition">
          <p class="lang-en-results__title">
            {{ userResults.audition.name }}
            <span class="lang-en-results__title-add-text">
              {{ userResults.audition.score }}/100
            </span>
          </p>
          <div :class="[
            'lang-en-results__progress-scale',
            'progress-scale',
            'progress-scale--bg-' + userResults.audition.color,
          ]">
            <div
              class="progress-scale__bar"
              :style="{ width: userResults.audition.score + '%' }"
            ></div>
          </div>
          <p
            class="lang-en-results__text"
            v-html="userResults.audition.description"
          ></p>
        </li>
        <li class="lang-en-results__item lang-en-results__item--reading">
          <p class="lang-en-results__title">
            {{ userResults.reading.name }}
            <span class="lang-en-results__title-add-text">
              {{ userResults.reading.score }}/100
            </span>
          </p>
          <div :class="[
            'lang-en-results__progress-scale',
            'progress-scale',
            'progress-scale--bg-' + userResults.reading.color
          ]">
            <div
              class="progress-scale__bar"
              :style="{ width: userResults.reading.score + '%' }"
            ></div>
          </div>
          <p
            class="lang-en-results__text"
            v-html="userResults.reading.description"
          ></p>
        </li>
        <li class="lang-en-results__item lang-en-results__item--grammar">
          <p class="lang-en-results__title">
            {{ userResults.grammar.name }}
            <span class="lang-en-results__title-add-text">
              {{ userResults.grammar.score }}/100
            </span>
          </p>
          <div :class="[
            'lang-en-results__progress-scale',
            'progress-scale',
            'progress-scale--bg-' + userResults.grammar.color
          ]">
            <div
              class="progress-scale__bar"
              :style="{ width: userResults.grammar.score + '%' }"
            ></div>
          </div>
          <p
            class="lang-en-results__text"
            v-html="userResults.grammar.description"
          ></p>
        </li>
        <li class="lang-en-results__item lang-en-results__item--vocabulary">
          <p class="lang-en-results__title">
            {{ userResults.vocabulary.name }}
            <span class="lang-en-results__title-add-text">
              {{ userResults.vocabulary.score }}/100
            </span>
          </p>
          <div :class="[
            'lang-en-results__progress-scale',
            'progress-scale',
            'progress-scale--bg-' + userResults.vocabulary.color
          ]">
            <div
              class="progress-scale__bar"
              :style="{ width: userResults.vocabulary.score + '%' }"
            ></div>
          </div>
          <p
            class="lang-en-results__text"
            v-html="userResults.vocabulary.description"
          ></p>
        </li>
      </ul>
    </div>
    <div class="mt-20">
      <a class="action-btn regular-btn mr-10" :href="startPath">Пройти заново</a>
      <a
        class="action-btn regular-btn icon icon--regular icon--download icon--white-color"
        :href="downloadPath"
        >
        Скачать результаты
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestingEngResult",

  props: {
    userResults: { type: Object, required: true },
    downloadPath: { type: String, required: true },
    startPath: { type: String, required: true },
    imgAuditionUrl: { type: String, required: true },
    imgReadingUrl: { type: String, required: true },
    imgGrammarUrl: { type: String, required: true },
    imgVocabularyUrl: { type: String, required: true },
  },

  data() {
    return {
      svgProps: {
        width: 500,
        height: 250,
        radius: 90,
      },
      rankBreakpoints: [0, 25, 46, 65, 85, 94, 100],
      rankNames: [
        "Elementary (A1)",
        "Pre-intermediate (A2)",
        "Intermediate (B1)",
        "Upper-intermediate (B2)",
        "Advanced (C1)",
        "Mastery (C2)",
      ],
    };
  },

  computed: {
    resultAngle() {
      var userResult = this.userResults.total.score;
      var i = this.userRankNum;
      var rankPoints = this.rankBreakpoints;
      var result =
        60 *
        (i +
          (userResult - rankPoints[i]) / (rankPoints[i + 1] - rankPoints[i]));

      return result >= 360 ? 359.9 : result;
    },

    userRankNum() {
      var x = this.userResults.total.score;
      var points = this.rankBreakpoints;

      switch (true) {
        case x <= points[1]:
          return 0;
          break;
        case x <= points[2]:
          return 1;
          break;
        case x <= points[3]:
          return 2;
          break;
        case x <= points[4]:
          return 3;
          break;
        case x <= points[5]:
          return 4;
          break;
        case x <= points[6]:
          return 5;
          break;
        default:
          return 0;
      }
    },
  },

  methods: {
    polarToCartesian(centerX, centerY, radius, angleInDegrees) {
      var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

      return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
      };
    },

    describeArc(x, y, radius, startAngle, endAngle) {
      var start = this.polarToCartesian(x, y, radius, endAngle);
      var end = this.polarToCartesian(x, y, radius, startAngle);

      var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

      var d = [
        "M",
        start.x,
        start.y,
        "A",
        radius,
        radius,
        0,
        largeArcFlag,
        0,
        end.x,
        end.y,
      ].join(" ");

      return d;
    },
    textAnchor(angle) {
      switch (true) {
        case angle == 0 || angle == 180:
          return "middle";
          break;
        case angle < 180:
          return "start";
          break;
        case angle > 180:
          return "end";
          break;
        default:
          return "start";
      }
    },
    alignmentBaseline(angle) {
      switch (true) {
        case angle <= 90 || angle >= 270:
          return "alphabetic";
          break;
        case angle > 90 && angle < 270:
          return "hanging";
          break;
        default:
          return "auto";
      }
    },
  }
};
</script>
