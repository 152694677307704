<template>
	<section class="catalog__section">
		<div class="catalog__section-header catalog__section-header--with-link">
			<template v-if="recommended">
				<template v-if="current_company === 'megafon'">
					<h2 class="catalog__title">
						{{ $t('recommended_megafon_plan') }}
					</h2>
					<p class="catalog__right" style="width: 215px;">
						<a href="/recommended_prescriptions" class="link-btn link-btn--light-font">
							{{ $t('buttons.prescription_all') }}
						</a>
					</p>
				</template>
				<template v-else>
					<h2 class="catalog__title">
						{{ $t('recommended_programms') }}
					</h2>
					<p class="catalog__right" style="width: 290px;">
						<a href="/recommended_prescriptions" class="link-btn link-btn--light-font">
							{{ $t('buttons.all_recommended_programms') }}
						</a>
					</p>
				</template>
			</template>
			<template v-else>
				<template v-if="current_company === 'megafon'">
					<h2 class="catalog__title">
						<template v-if="!filteredSelection(selection).length">
							{{ $t('megafon_plan') }}
						</template>
						<template v-else>
							{{ $t('megafon_plan_completed_to') }}
							<span class="sub-light-font-color">{{ learning_plan_result }}%</span>
						</template>
					</h2>
					<p class="catalog__right">
						<a href="/prescriptions" class="link-btn link-btn--light-font">
							{{ $t('buttons.prescription_all') }}
						</a>
					</p>
				</template>
				<template v-else>
					<h2 class="catalog__title">
						<template v-if="!filteredSelection(selection).length">
							{{ $t('learning_plan') }}
						</template>
						<template v-else>
							{{ $t('learning_plan_completed_to') }}
							<span class="sub-light-font-color">{{ learning_plan_result }}%</span>
						</template>
					</h2>
					<p class="catalog__right" style="width:240px;">
						<a href="/prescriptions" class="link-btn link-btn--light-font">
							{{ $t('buttons.all_required_programms') }}
						</a>
					</p>
				</template>
			</template>
		</div>

		<Carousel
			v-if="filteredSelection(selection).length"
			class="catalog__list catalog__list--plan"
			:itemsToShow="4"
			:itemsToScroll="4"
			snapAlign="start"
		>
			<Slide
				v-for="(prescription, index) in filteredSelection(selection)"
				:key="'section-' + prescription.id + '-' + index"
				class="catalog__item"
			>
				<PlanPreview
					:prescription="prescription"
					:currentCompany="current_company"
					:recommended="recommended"
				/>
			</Slide>
			<template v-if="filteredSelection(selection).length > 4" #addons>
				<Navigation />
			</template>
		</Carousel>
		<template v-else>
			<template v-if="recommended">
				<div v-if="current_company === 'megafon'" class="page-card page-card--bordered message-card message-card--inline message-card--with-centred-icon message-card--empty-learning-plan">
					<p class="message-card__label">{{ $t('messages.recommended_megafon_1') }}</p>
					<p class="message-card__title">{{ $t('messages.recommended_megafon_2') }}</p>
				</div>
				<div v-else class="page-card page-card--bordered message-card message-card--inline message-card--with-centred-icon message-card--empty-learning-plan">
					<p class="message-card__label">{{ $t('messages.no_recommended_training') }}</p>
					<p class="message-card__title">{{ $t('messages.choose_description') }}</p>
					<a href="/courses" class="regular-btn action-btn">
						{{ $t('buttons.choose_course') }}
					</a>
				</div>
			</template>
			<template v-else>
				<div v-if="current_company === 'megafon'" class="page-card page-card--bordered message-card message-card--inline message-card--with-centred-icon message-card--empty-learning-plan">
					<p class="message-card__label">{{ $t('messages.megafon_1') }}</p>
					<p class="message-card__title">{{ $t('messages.megafon_2') }}</p>
				</div>
				<div v-else class="page-card page-card--bordered message-card message-card--inline message-card--with-centred-icon message-card--empty-learning-plan">
					<p class="message-card__label">{{ $t('messages.no_training') }}</p>
					<p class="message-card__title">{{ $t('messages.choose_description') }}</p>
					<a href="/courses" class="regular-btn action-btn">
						{{ $t('buttons.choose_course') }}
					</a>
				</div>
			</template>
		</template>
	</section>
</template>

<script>
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import PlanPreview from "./PlanPreview.vue";

export default {
	name: "PlanSelection",

	components: {
		Carousel,
		Slide,
		Navigation,
		PlanPreview
	},

	props: {
		selection: Array,
		learning_plan_result: String,
		newCourses: {
			type: Boolean,
			default: false,
		},
		current_company: {
			type: String,
			default: ''
		},
		recommended: {
			type: Boolean,
			default: false
		},
	},

	data() {
		return {
			uncompletedOnly: false,
			loading: true,
			isContinue: false,
			currentLocale: window.currentLocale
		};
	},

	methods: {
		filteredSelection(selection) {
			return this.uncompletedOnly
				? selection.filter((prescription) => prescription.result != 100)
				: selection;
		}
	}
};
</script>
