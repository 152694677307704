<template>
  <form novalidate="novalidate" accept-charset="UTF-8" @submit.prevent="onSubmit">
    <section class="page-card__section page-card__section--row page-card__section--brd-solid">
      <div class="page-card__col page-card__col--half">
        <label class="labeled-field labeled-field--required">
          <span class="labeled-field__label">{{ t.fields.questionnaire.name.label }}</span>
          <input
            name="questionnaire[name]"
            type="text"
            class="labeled-field__input"
            v-model.trim="questionnaire.title"
            :class="[ errors.title ? 'error-field' : '' ]"
            @input="questionnaireFieldUpdate"
          >
          <span class="labeled-field__caption">{{ t.fields.questionnaire.name.hint }}</span>
        </label>
      </div>
      <div class="page-card__col page-card__col--half">
        <label class="labeled-field">
          <span class="labeled-field__label">{{ t.fields.questionnaire.description.label }}</span>
          <textarea
            class="labeled-field__input"
            name="questionnaire[description]"
            :placeholder="t.fields.questionnaire.description.placeholder"
            v-model.trim="questionnaire.description"
            @input="questionnaireFieldUpdate"
          ></textarea>
        </label>
      </div>
    </section>

    <section class="page-card__section">
      <h2 class="page-card__subtitle">{{ t.fields.questionnaire.questions.field_set_title }}</h2>
      <div class="questionnaires__list-questions">
        <template v-for="(question, question_index) in sortedQuestions">
          <QuestionnairesQuestion
            v-if="!question._destroy"
            :key="'question.' + question_index"
            :question="question"
            :question_index="question_index"
          />
        </template>
      </div>
    </section>

    <footer class="page-card__footer">
      <button class="regular-btn action-btn mr-10" @click.prevent="addQuestion" type="submit">{{ t.navs.add_question }}</button>
      <button v-if="isAssignmentAvailable()"
              class="regular-btn white-btn j-questionnaire-assignment-link"
              data-target=".j-questionnaire-assignment-popup"
              :data-url="assignmentUrl"
              @click="showAssignmentPopup"
              type="button"
      >
        {{ t.navs.assign }}
      </button>
      <a :href="formUrl" rel="nofollow" data-method="delete" data-confirm="Are you sure?" class="regular-btn red-btn ml-auto">
        {{ t.navs.destroy_questionnaire }}
      </a>
    </footer>
  </form>
</template>

<script>
import axios from 'axios';
import { debounce } from 'vue-debounce';
import QuestionnairesQuestion from './Question.vue';

export default {
  name: 'QuestionnairesForm',

  components: { QuestionnairesQuestion },

  props: {
    questionnaire_data: { type: Object, required: true },
    form_url: { type: String, required: true },
    new_record: { type: Boolean, required: true },
    assignmentExist: { type: Boolean, required: true },
    assignment_url: { type: String, required: true }
  },

  data() {
    return {
      state: '',
      errors: {},
      isNewQuestionnaire: this.new_record,
      formUrl: this.form_url,
      questionnaire: this.questionnaire_data,
      questionPlaceholder: { title: '', question_type: 'one', required: true, _destroy: false, answers: [] },
      answerPlaceholder: { title: '', answer_type: 'default', _destroy: false },
      assignmentUrl: this.assignment_url,
      t: I18n.t.questionnaire_dashboard_form
    }
  },

  methods: {
    onSubmit() {
      this.state = 'loading'
      axios({
        response_type: "json",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        method: this.formMethod(),
        url: this.formUrl,
        data: { questionnaire: this.getQuestionnaireFormData(this.questionnaire) }
      }).then(response => {
        this.errors = {}
        this.isNewQuestionnaire = false
        this.formUrl = response.data.form_url
        this.questionnaire.id = response.data.questionnaire.id
        this.questionnaire.question_create_url = response.data.questionnaire.question_create_url
        this.assignmentExist = response.data.questionnaire.assignment_exist
        this.assignmentUrl = response.data.questionnaire.assignment_url
        this.changeBrowserUrl()
        this.state = 'synced'
      }).catch(errors => {
        this.errors = errors.response.data.errors
        this.state = 'error'
      })
    },

    questionnaireFieldUpdate() {
      this.state = 'modified'
      this.debouncedQuestionnaireUpdate()
    },

    debouncedQuestionnaireUpdate: debounce(function() {
      this.onSubmit()
    }, 800),

    formMethod() { return this.isNewQuestionnaire ? 'POST' : 'PATCH' },

    changeBrowserUrl() {
      window.history.replaceState({ questionnaire_id: this.questionnaire.slug }, null, `${this.formUrl}/edit`);
    },

    addQuestion() {
      // Need deep clone becouse object contain array of answers
      let question = JSON.parse(JSON.stringify(this.questionPlaceholder))
      this.questionnaire.questions = this.questionnaire.questions.filter(q => !q._destroy)

      question.pos = (this.questionnaire.questions.length || 0) + 1
      this.questionnaire.questions.push(question)

      axios({
        response_type: "json",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        method: 'POST',
        url: this.questionnaire.question_create_url,
        data: { questionnaire_id: this.questionnaire.id, question: question }
      }).then(response => {
        question.id = response.data.question.id;
        question.update_url = response.data.question.update_url;
        question.create_answer_url = response.data.question.create_answer_url;
        this.state = 'synced';
      }).catch(errors => {
        this.state = 'error';
      })
    },

    getQuestionnaireFormData(questionnaire) {
      return {
        title: questionnaire.title,
        description: questionnaire.description
      }
    },

    isAssignmentAvailable() {
      return !this.assignmentExist && this.assignmentUrl.length > 1 && this.questionnaire.questions.some(question => question.answers.length > 1);
    },

    showAssignmentPopup(event) {
      const popupSelector = event.target.dataset.target;
      const formBodyHolder = document.querySelector('.j-modal-content');
      const loader = `<div class="loading-spinner popup-assignment-loading-holder"></div>`;

      formBodyHolder.innerHTML = loader;
      show_popup(popupSelector);

      axios({
        response_type: "json",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        method: 'GET',
        url: this.assignmentUrl
      }).then(response => {
        formBodyHolder.innerHTML = response.data.html;

        let modalContent = document.querySelector('.j-modal-content');
        modalContent.querySelectorAll('.dropdown-field-holder').forEach(field => new Dropdown(field));
        modalContent.querySelectorAll('.j-datepicker').forEach(field => new Pikaday(this.pikadayOption(field)));
      }).catch(errors => {
        hide_popup(popupSelector);
      })
    },

    pikadayOption(field) {
      let options = {
        field: field,
        theme: "pikaday-theme",
        firstDay: 1 * moment().startOf("week").format("E"),
        i18n: {
          months: _.map(moment.months(), capitalize),
          weekdays: _.map(moment.weekdays(), capitalize),
          weekdaysShort: _.map(moment.weekdaysShort(), capitalize),
        },
      };

      return options;
    }
  },

  computed: {
    sortedQuestions() {
      return this.questionnaire.questions.filter(q => !q._destroy).sort((a, b) => a.pos < b.pos);
    }
  },
}
</script>
