<template>
  <div
    class="quiz-drag-columns has-no-gap"
    v-for="(vallist, key, index) in values3"
    :key="vallist.pos"
  >
    <div class="quiz-drag-list mt-10">
      <div class="quiz-drag-box">
        <div class="quiz-drag-item is-comparison-statement">
          <div class="quiz-drag-item-main">
            <p class="bold-font">{{ vallist.body }}</p>
          </div>
        </div>
      </div>
    </div>
    <draggable
      :class="['quiz-drag-list is-empty limited-1 mt-10']"
      :list="answers[index]"
      :group="draggableAnswersOptions[index]"
      :sortable="false"
      @add="checkAdd"
      @change="checkChange"
      v-bind="dragOptions"
    >
      <transition-group type="transition" class="quiz-drag-transition">
        <div class="quiz-drag-box" v-for="(val) in answers[index]" :key="val.pos">
          <div class="quiz-drag-item is-comparison-match">
            <div class="quiz-drag-item-main">
              <p class="bold-font">{{ val.secondary_body }}</p>
            </div>
          </div>
        </div>
      </transition-group>
    </draggable>
  </div>

  <div class="mt-30">
    <p class="small-title-font-size sub-light-font-color">Варианты</p>
    <draggable
      class="quiz-drag-list has-two-columns mt-10"
      :list="values"
      group="answers_group"
      @add="checkAdd"
    >
      <div class="quiz-drag-box" v-for="(val) in values" :key="val.pos">
        <div class="quiz-drag-item">
          <div class="quiz-drag-item-main">
            <p class="bold-font">{{ val.secondary_body }}</p>
          </div>
        </div>
      </div>
    </draggable>
  </div>
</template>

<script>
export default {
  name: "QuizQuestionComparison",

  props: {
    question: Object
  },

  data() {
    return {
      value: [],
      answers: [],
      prevAnswers: [],
      prevValues: [],
      values: this.question.answers,
      values3: Object.assign(
        {},
        this.question.answers.sort((a, b) => a.pos - b.pos)
      ),
      draggableAnswersOptions: []
    };
  },

  computed: {
    dragOptions() {
      return {
        animation: 200,
        ghostClass: "ghost"
      };
    }
  },

  mounted() {
    var x = this;
    this.values.forEach(function() {
      x.answers.push([]);
      x.draggableAnswersOptions.push({
        name: "answers_group",
        pull: () => true,
        put: true
      });
    });

    this.savePrevState();
  },

  methods: {
    checkChange() {
      this.$emit(
        "set-answer",
        this.answers.map((x, i) => x[0])
      );
    },

    checkAdd(e) {
      this.checkAnswers();
    },

    checkAnswers() {
      let temp = this;
      let answers_with_two = this.answers.filter((x, i) => {
        return x.length > 1;
      });
      if (answers_with_two.length) {
        if (this.values.length == this.prevValues.length) {
          this.processSwapInAnswers();
        } else {
          this.processSwapBetweenAnswersAndValues();
        }
      }

      this.savePrevState();
    },

    processSwapInAnswers() {
      var indexes = [];

      this.answers.map((x, i) => {
        if (x.length != this.prevAnswers[i].length) {
          indexes.push(i);
        }
      });

      var results = this.prevAnswers.map(function(arr) {
        return arr.slice();
      });
      this.swapTwoElements(results, indexes[0], indexes[1]);

      this.answers = results;
    },

    processSwapBetweenAnswersAndValues() {
      let results = this.answers.map((x, i) => {
        if (x.length > 1) {
          this.values.push(x.filter(y => !this.prevValues.includes(y))[0]);
          return x.filter(y => {
            return this.prevValues.includes(y);
          });
        } else {
          return x;
        }
      });

      this.answers = results;
    },

    swapTwoElements(array, index1, index2) {
      let a = array[index1].slice();
      let b = array[index2].slice();

      array[index2] = a;
      array[index1] = b;
    },

    savePrevState() {
      this.prevAnswers = this.answers.map(function(arr) {
        return arr.slice();
      });

      this.prevValues = this.values.slice();
    }
  },
};
</script>
