<template>
	<div class="vote" style="display:block;height:auto;">
		<template v-if="isShowStars">
			<h3 class="bold-font mb-10 text-center">Оцените урок</h3>
			<div class="vote__btns-holder">
				<button
					v-for="n in 5"
					@click="voteBtnHandler(n)"
					class="vote__btn"
					:class="{ 'is-active': n <= rating }"
					type="button"
				></button>
			</div>
		</template>
		<template v-if="isShowСhoices">
			<h3 v-if="rating === 4 || rating === 5" class="bold-font mb-10">Что вам понравилось?</h3>
			<h3 v-else class="bold-font mb-10">Что вам не понравилось?</h3>
			<div class="vote__choices-holder">
				<label v-for="choice in choices" class="vote__choice-label">
					<input
						class="visually-hidden"
						type="checkbox"
						v-model="proposal"
						:value="choice"
					>
					<span class="vote__choice-btn small-btn white-btn">{{ choice }}</span>
				</label>
			</div>

			<label class="labeled-field mt-15" for="id-message" style="width:100%;">
				<span class="labeled-field__label">Как мы могли бы улучшить курс?</span>
				<textarea id="id-message" class="labeled-field__input" v-model="proposalOther"></textarea>
			</label>

			<button class="regular-btn action-btn mt-15" @click="submitForm">Отправить</button>
		</template>
		<template v-if="isShowResults">
			<div v-if="isError"  class="text-center">{{ error_message }}</div>
			<div v-else class="text-center">{{ success_message }}</div>
		</template>
	</div>
</template>

<script>
import axios from "axios";
import { ref } from 'vue'

export default {
	name: 'CourseCompleted',

	props: {
		course_id: Number,
		url: String,
		success_message: String,
		error_message: String,
		choices: {
			type: Array,
			default: [
				'Практическое применение',
				'Работа спикера',
				'Содержание курса',
				'Картинка и звук',
				'Другое'
			]
		}
	},

	data() {
		return {
			rating: 0,
			proposal: ref([]),
			isShowStars: true,
			isShowResults: false,
			isShowСhoices: false,
			isError: false,
			proposalOther: ''
		}
	},

	methods: {
		voteBtnHandler(n) {
			this.rating = n;
			this.isShowStars = false;
			this.isShowСhoices = true;
		},

		submitForm() {
			this.isShowForm = false;
			this.isShowСhoices = false;
			this.isShowStars = false;
			this.isShowResults = true;

			if (this.proposalOther) {
				this.proposal.push(this.proposalOther)
			}

			this.sendFormData();
		},

		sendFormData() {
			axios.request({
				headers: {
					"X-Requested-With": "XMLHttpRequest",
					"X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
					"Content-Type": "application/json"
				},
				responseType: "json",
				url: this.url,
				method: "POST",
				data: {
					feedback: {
						proposal: this.proposal,
						rating: this.rating,
						course_id: this.course_id
					}
				}
			}).then((response) => {
				this.isError = false;
				this.rating = 0;
				this.proposal = '';
			}).catch((error) => {
				this.isError = true;
			})
		},

	}
}
</script>
