<template>
  <div class="progress-item popup-trigger j-show-assignment-popup-open" :data-url="assignment_item.statistics_company_programs_path" tabindex="0">
    <div class="progress-item__main">
      <div class="progress-item__right progress-item__right--align-middle">
        <h3 class="progress-item__title">{{ assignment_item.name }}</h3>
        <p class="progress-item__descr">
          <slot></slot>
        </p>
      </div>
      <div class="progress-item__left" v-show="needDisplayProgress()" v-html="progressValue"></div>
    </div>
    <div class="progress-item__progress">
      <div class="progress-item__scale" :class="'progress-item__scale--' + assignment_item.progress_status">
        <span class="progress-item__bar" :style="{ width: assignment_item.progress_in_percent + '%' }"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgramAssignmentItem',

  props: {
    assignment_item: Object
  },

  data() {
    return {
      progressValue: ''
    }
  },

  mounted() {
    this.displayProgress();
  },

  methods: {
    needDisplayProgress() {
      if (!this.assignment_item.is_onboarding || this.assignment_item.is_onboarding && this.assignment_item.count_completed > 0) return true
      return false
    },

    displayProgress() {
      if (this.assignment_item.progress_in_percent == 100) {
        this.progressValue = "<span class=\"icon icon--box icon--check icon--tiny icon--high-color\"></span>"
      }
      else {
        if (this.assignment_item.is_onboarding)
          this.progressValue = this.assignment_item.count_completed_text
        else
          this.progressValue = this.assignment_item.progress_in_percent + "%"
      }
    }
  },
}
</script>
