<script>
import axios from 'axios';

export default {
  name: "QuestionnairesResponseForm",
  template: `<slot v-bind="self" />`,

  props: {
    demo: { type: Boolean, default: false },
    questionnaire_id: { type: Number, required: true },
    questions: { type: Array, required: true },
    form_url: { type: String, required: true }
  },

  data() {
    return {
      users_answers_many: {},
      users_answers_one: {},
      errors: { question_ids: [], answer_ids: [] },
      is_form_sending: false,
      is_completed: false,
      user_questions: this.questions
    }
  },

  computed: {
    self() {
      return this;
    },
  },

  created() {
    this.users_answers_many = this.buildUsersAnswersMany();
    this.users_answers_one = this.buildUsersAnswersOne();
  },

  methods: {
    submitForm() {
      this.is_form_sending = true;
      if (this.isFormValid()) {
        axios({
          response_type: "json",
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content")
          },
          method: "POST",
          url: this.form_url,
          data: { id: this.questionnaire_id, questionnaire_form: this.preparedUsersAnswersParams() }
        }).then(response => {
          this.is_completed = true;
          this.is_form_sending = false;
        })
      } else {
        this.is_form_sending = false;
      }
    },

    preparedUsersAnswersParams() {
      return { users_answers: [...this.preparedUsersAnswersManyChoices(), ...this.preparedUsersAnswersOneChoice()] }
    },

    preparedUsersAnswersManyChoices() {
      return Object.keys(this.users_answers_many)
             .filter(answer_id => this.users_answers_many[answer_id].checked)
             .map(answer_id => {
               return {
                 "questionnaire_answer_id": Number(answer_id),
                 "questionnaire_question_id": this.users_answers_many[answer_id].question_id,
                 "value": this.users_answers_many[answer_id].value
               }
             })
    },

    preparedUsersAnswersOneChoice() {
      return Object.keys(this.users_answers_one)
             .filter(question_id => this.users_answers_one[question_id].answer_id)
             .map(question_id => {
               return {
                 "questionnaire_answer_id": this.users_answers_one[question_id].answer_id,
                 "questionnaire_question_id": Number(question_id),
                 "value": this.users_answers_one[question_id].value
               }
             })
    },

    isFormValid() {
      return !this.isDemoMode() && this.isRequiredQuestionsAnswered() && this.isCustomAnswersFilled();
    },

    isDemoMode() {
      if (this.demo) {
        this.errors["demo_mode"] = "Demo mode";
        return true;
      } else {
        return false;
      }
    },

    isRequiredQuestionsAnswered() {
      let answeredQuestions = this.answeredQuestionIds();

      let notAnsweredQuestions = this.requiredQuestionIds()
        .filter(requiredQuestionId => !answeredQuestions.includes(requiredQuestionId));

      let isValid = notAnsweredQuestions.length <= 0;
      this.errors["question_ids"] = isValid ? [] : notAnsweredQuestions;

      return isValid;
    },

    isCustomAnswersFilled() {
      let customAnswersFieldIds = [];

      this.questions.forEach(question => {
        customAnswersFieldIds = [...customAnswersFieldIds, ...question.answers.filter(answer => answer.answer_type == "open").map(answer => answer.id)]
      })

      let notFilledIdsMany = Object.keys(this.users_answers_many)
        .filter(answer_id => {
          return customAnswersFieldIds.includes(Number(answer_id)) &&
              this.users_answers_many[answer_id].checked &&
              (!this.users_answers_many[answer_id].value || this.users_answers_many[answer_id].value.length <= 0)
        })
        .map(answer_id => Number(answer_id))

      let notFilledIdsOne = Object.keys(this.users_answers_one)
        .filter(question_id => {
          return this.users_answers_one[question_id].answer_id &&
              customAnswersFieldIds.includes(this.users_answers_one[question_id].answer_id) &&
              (!this.users_answers_one[question_id].value || this.users_answers_one[question_id].value.length <= 0)
        })
        .map(question_id => Number(this.users_answers_one[question_id].answer_id))

      let notFilledAnswersIds = [...notFilledIdsMany, ...notFilledIdsOne]
      let isValid = notFilledAnswersIds <= 0
      this.errors["answer_ids"] = isValid ? [] : notFilledAnswersIds

      return isValid
    },

    answeredQuestionIds() {
      let answeredQuestionWithMany = Object.keys(this.users_answers_many)
                         .filter(answer_id => this.users_answers_many[answer_id].checked)
                         .map(answer_id => Number(this.users_answers_many[answer_id].question_id))

      let answeredQuestionWithOne = Object.keys(this.users_answers_one)
                        .filter(question_id => this.users_answers_one[question_id].answer_id)
                        .map(question_id => Number(question_id))

      return [...new Set([...answeredQuestionWithMany, ...answeredQuestionWithOne])];
    },

    requiredQuestionIds() {
      return this.user_questions.filter(question => question.required).map(question => question.id);
    },

    buildUsersAnswersMany() {
      let result = {}

      this.user_questions.filter(q => q.question_type == 'many').forEach(question => {
        question.answers.forEach(answer => {
          result[answer.id] = { question_id: question.id, checked: false, value: null }
        })
      })

      return result
    },

    buildUsersAnswersOne() {
      let result = {};
      this.user_questions
        .filter(q => q.question_type == "one")
        .forEach(question => result[question.id] = { answer_id: null, value: null });
      return result;
    },

    inputAnswerOnQuestionWithTypeMany(answerObject) {
      if (answerObject.value.length > 0) {
        answerObject.checked = true;
      } else {
        answerObject.checked = false;
      }
    },

    inputAnswerOnQuestionWithTypeOne(answerObject, answer_id) {
      if (answerObject.value.length > 0) {
        answerObject.answer_id = answer_id;
      } else {
        answerObject.answer_id = null;
      }
    },

    isQuestionErrorsExist() {
      return this.errors['question_ids'].length > 0;
    },

    isAnswerErrorsExist() {
      return this.errors['answer_ids'].length > 0;
    },

    isQuestionHasError(question_id) {
      return this.errors['question_ids'].includes(question_id);
    },

    isAnswerHasError(answer_id) {
      return this.errors['answer_ids'].includes(answer_id);
    }
  }
}
</script>
