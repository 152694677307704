<template>
  <template v-if="question.filling_type == 'input'">
    <label
      v-for="(val, index) in trueAnswers"
      class="quiz-filling-text-field" :id="'ans-q' + (index+1)"
    >
        <input
          v-on:blur="$emit('set-answer', trueAnswers)"
          v-model='val.secondary_body'
          type='text'
          class='quiz-filling-text-field-input'
        />
    </label>
  </template>

  <template v-if="question.filling_type == 'select'">
    <label
      v-for="(group) in groupedAnswers"
      class="quiz-filling-select" :id="'ans-' +group[0].body"
    >
        <select
          v-model='trueAnswers[groupedAnswers.indexOf(group)]'
          v-on:blur="$emit('set-answer', trueAnswers)"
          class="quiz-filling-select-input"
        >
          <option v-for="val in group" v-bind:value="val">
            {{ val.secondary_body }}
          </option>
        </select>
    </label>
  </template>

  <template v-if='false'>
    <div
      v-for="(val) in values"
      :key="val.pos"
    >
      <draggable :id="'ans-' +val.body"
        :list="values"
      >
        <div
          class="quiz-drag-box"
        >
          <div class="quiz-drag-item">
              <div class="quiz-drag-item-main">
                  <p class="bold-font">{{ val.secondary_body }}</p>
              </div>
          </div>
        </div>
      </draggable>
    </div>
  </template>

  <div class="mt-15">
    <p class="quiz-filling-text" v-html="questionText"></p>

    <!-- <component class="quiz-filling__text" v-bind:is="processedQuestionText"></component> -->
  </div>

  <div class="mt-30" v-if="fillingType == 'choosing'">
    <p class="small-title-font-size sub-light-font-color">Варианты</p>
    <draggable
      class="quiz-drag-list has-two-columns mt-10"
      :list="values"
      group="answers_group"
    >
      <div
        class="quiz-drag-box"
        v-for="(val) in values"
        :key="val.pos"
      >
        <div class="quiz-drag-item">
            <div class="quiz-drag-item-main">
                <p class="bold-font">{{ val.body }}</p>
            </div>
        </div>
      </div>
    </draggable>
  </div>
</template>

<script>
export default {
  name: 'QuizQuestionFilling',

  props: {
    question: Object
  },

  data() {
    return {
      trueAnswers: [],
      value: [],
      answers: this.clearAnswers(),
      groupedAnswers: this.groupAnswers(),
      values: this.question.answers,
      fillingType: 'input',
      questionText: this.question.filling_text,
      questionText1: 'Гора Эверест является {q1} вершиной земного шара и находится в {q2}. Ее высота — {q3} м. Гора разделена границей Непала и Китая (Тибетский автономный район), однако ее вершина лежит на территории {q4}.',
      inputs: [],
    }
  },

  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },

    processedQuestionText() {
      let parsedText = this.questionText
      parsedText = parsedText.replace(/{q1}/g, '<input-text></input-text>')

      return {
        template: '<div>' + parsedText + '</div>'
      }
    }
  },

  created() {
  },

  mounted() {
      this.values1 = [
      {
        pos: 1,
        secondary_body: '8848'
      },
      {
        pos: 2,
        secondary_body: 'высочайшей'
      },
      {
        pos: 3,
        secondary_body: 'Непале'
      },
      {
        pos: 4,
        secondary_body: 'Китая'
      }
    ]
    this.testProcessedQuestionText()
    this.setTrueAnswers()

    setTimeout(()=>{
      this.replaceInputs()
    },500);
  },

  methods: {
    setTrueAnswers(){
      var ans = []
      var tmp_root = this
      if (this.question.filling_type == 'input'){
        ans = this.question.answers.slice(0, tmp_root.inputs.length).map(function(a, index) {
          a.body = 'q'+ (index+1)
          return a
        });
      } else if (this.question.filling_type == 'select'){
        ans = Array.apply(null, {length: tmp_root.inputs.length}).map(Number.call, Number)
      }
      this.trueAnswers = ans
    },

    randomAnswers(){
      return this.question.answers.sort(this.compareRandom)
    },

    compareRandom(a, b) {
      return Math.random() - 0.5;
    },

    clearAnswers(){
      let tmpAnswers = this.question.answers
      if (this. question.filling_type == 'input') {
        tmpAnswers.map(function(a) {
          a.secondary_body = ''
          return a
        });
      }
      return tmpAnswers
    },

    groupAnswers(){
      let tmpAnswers = this.question.answers
      let q1 = []
      let q2 = []
      let q3 = []
      let q4 = []
      let q5 = []
      let q6 = []
      tmpAnswers.map(function(a) {
        switch(a.body) {
          case 'q1':
            q1.push(a)
            break;
          case 'q2':
            q2.push(a)
            break;
          case 'q3':
            q3.push(a)
            break;
          case 'q4':
            q4.push(a)
            break;
          case 'q5':
            q5.push(a)
            break;
          case 'q6':
            q6.push(a)
            break;
          default:
            break;
        }
      });
      var filtered = [q1, q2, q3, q4, q5, q6].filter(function (el) {
        return el.length > 0
      });

      return filtered
    },

    testProcessedQuestionText() {
      if (this.fillingType == 'input') {
        var temp = this
        this.questionText = this.questionText.replace(/{q\d+}/g, function(match) {
          match = match.replace('{', '');
          match = match.replace('}', '');
          temp.inputs.push(match);
          return "<span id=" + match + "></span>"
        })
      }
      else if (this.fillingType == 'select') {
        var temp = this
        this.questionText = this.questionText.replace(/{q\d+}/g, function(match) {
          return `<select v-model="selected"><option disabled value="">Выберите один из вариантов</option><option>А</option><option>Б</option><option>В</option></select>`
        })
      }
      else {
        var temp = this
        this.questionText = this.questionText.replace(/{q\d+}/g, function(match) {
          match = match.replace('{', '');
          match = match.replace('}', '');
          temp.inputs.push(match);
          return "<span id=" + match + "></span>"
        })
      }
    },

    replaceInputs(){
      $('.quiz-filling-text span').each(function(i, el){
        $(el).after($('#ans-'+$(el).attr('id')))
      });
    }
  },
}
</script>
