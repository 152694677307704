<template>
	<section class="page-card">
		<div class="page-card__header">
			<div class="page-card__row page-card__row--align-center mb-5">
                <a class="link-btn link-btn--back" href="/ru/dashboard/homework_attempts">Назад</a>
                <div>
					<a class="regular-btn action-btn" :href="lecture_url">
						Открыть ДЗ
					</a>
                </div>
            </div>

			<h2 class="page-card__title mb-20">
				{{ homework_attempt.homework.lecture.name }}
			</h2>
			<p class="mb-15">
				<span class="bold-font">Компания: </span>
				<span class="thin-font">{{ homework_attempt.user.company.name }}</span>
			</p>
			<p class="mb-15">
				<span class="bold-font">Имя студента: </span>
				<span class="thin-font">{{ homework_attempt.user.name }}</span>
			</p>
			<p class="mb-15">
				<span class="bold-font">Email студента: </span>
				<a :href="`${homework_attempt.user.email}`" target="_blank" rel="noopener noreferrer">{{ homework_attempt.user.email }}</a>
			</p>
			<p class="mb-15">
				<span class="bold-font">Дата отправки попытки на проверку: </span>
				<span class="thin-font">{{ formatDate(homework_attempt.created_at) }}</span>
			</p>
		</div>

		<div class="page-card__main">
			<div class="page-card__section page-card__section--brd-solid">
				<div class="page-card__col page-card__col--main">
					<div class="page-card__row align-bottom mb-10">
						<label class="labeled-field labeled-field--select flex-grow" for="id-homework-reviewer" >
							<span class="labeled-field__label">Проверяет:</span>
							<select
								id="id-homework-reviewer"
								class="labeled-field__input"
								v-model="selectedReviewer"
							>
								<option value="">Выберите проверяющего</option>
								<option v-for="reviewer in reviewers" :key="reviewer.id" :value="reviewer.id">
									{{ reviewer.email }}
								</option>
							</select>
						</label>
						<button @click="submitReviewerChange" class="regular-btn white-btn ml-10" type="button" style="width:115px;">
							<span v-if="isSubmitFields.reviewer_id" class="icon icon--check icon--tiny icon--high-color">Готово!</span>
							<template v-else>Применить</template>
						</button>
					</div>

					<div class="page-card__row align-bottom mb-10">
						<label class="labeled-field labeled-field--select flex-grow" for="id-homework-crosschecker">
							<span class="labeled-field__label">Кроссчекает:</span>
							<select
								id="id-homework-crosschecker"
								class="labeled-field__input"
								v-model="selectedCrossChecker"
							>
								<option value="">Выберите кроссчекера</option>
								<option v-for="checker in reviewers" :key="checker.id" :value="checker.id">
									{{ checker.email }}
								</option>
							</select>
						</label>
						<button @click="submitCrossCheckerChange" class="regular-btn white-btn ml-10" type="button" style="width:115px;">
							<span v-if="isSubmitFields.cross_checker_id" class="icon icon--check icon--tiny icon--high-color">Готово!</span>
							<template v-else>Применить</template>
						</button>
					</div>

					<div class="page-card__row align-bottom">
						<label class="labeled-field labeled-field--select flex-grow" for="id-homework-status">
							<span class="labeled-field__label">Статус ДЗ:</span>
							<select
								id="id-homework-status"
								class="labeled-field__input"
								v-model="selectedStatus"
							>
								<option v-for="status in statuses" :key="status.value" :value="status.value">
									{{ status.text }}
								</option>
							</select>
						</label>
						<button @click="submitStatusChange" class="regular-btn white-btn ml-10" type="button" style="width:115px;">
							<span v-if="isSubmitFields.status" class="icon icon--check icon--tiny icon--high-color">Готово!</span>
							<template v-else>Применить</template>
						</button>
					</div>
				</div>
			</div>

			<div class="mb-20">
				<h3 class="label mb-5">Текст задания:</h3>
				<div class="longread items-in-set items-in-set--medium scrollable" v-html="homework_attempt.homework.body"></div>
			</div>

			<div class="mb-20">
				<h3 class="label mb-5">Ответ пользователя:</h3>
				<div v-if="homework_attempt.answer" class="longread items-in-set items-in-ser--medium scrollable">
					{{ homework_attempt.answer }}

					<template v-if="homework_attempt.attachments.length">
						<h3 class="label mb-5 mt-20">Прикрепленные файлы</h3>
						<div class="files-preview-list">
							<div
								v-for="(attachment, index) in homework_attempt.attachments"
								:key="index"
								class="files-preview-list__item"
							>
								<span class="files-preview-list__check icon icon--check icon--tiny icon--high-color" />
								<a :href="attachment.file_url_original" target="_blank" rel="noopener noreferrer" class="files-preview-list__name">{{ attachment.file_name }}</a>
							</div>
						</div>
					</template>
				</div>
				<p v-else class="thin-font large-line-height">Студент пока не прислал свой ответ</p>
			</div>

			<div class="page-card__col page-card__col--main mb-20">
				<label class="labeled-field" for="id-homework-control">
					<span class="labeled-field__label">Ответ студенту:</span>
					<textarea
						id="id-homework-control"
						class="labeled-field__input"
						v-model="controlComment"
						placeholder="Напишите здесь свой ответ"
					></textarea>
				</label>
				<button class="regular-btn start-btn mt-10" @click="submitControlComment" type="button">Отправить ответ</button>
			</div>

			<div v-if="homework_attempt.control" class="mb-20">
				<h3 class="label mb-5">Ваш отправленный ответ</h3>
				<p class="longread items-in-set items-in-set--medium scrollable">{{ homework_attempt.control }}</p>
			</div>

			<section class="page-card__section page-card__section--brd-top-solid">
				<button class="link-btn" @click="showComments">
					<template v-if="isShowComments">- Скрыть комментарии проверяющих</template>
					<template v-else>+ Показать комментарии проверяющих</template>
				</button>
				<div v-show="isShowComments" class="mt-20">
					<div v-if="homework_attempt.homework_attempt_comments.length">
						<h3 class="label mb-5">Комментарии проверяющих</h3>
						<div class="page-card__section page-card__section--brd-dashed" v-for="comment in homework_attempt.homework_attempt_comments">
							<p class="mb-10">
								<span class="bold-font">Проверяющий: </span>
								<span class="thin-font">{{ comment.user.email }}</span>
							</p>
							<p class="mb-10">
								<span class="bold-font">Комментарий: </span>
								<span class="thin-font">{{ comment.body }}</span>
							</p>
							<p>
								<span class="bold-font">Дата: </span>
								<span class="thin-font">{{ formatDate(comment.created_at) }}</span>
							</p>
						</div>
					</div>

					<div class="page-card__col page-card__col--main mt-30">
						<label class="labeled-field" for="id-homework-comment">
							<span class="labeled-field__label">Комментарий к попытке:</span>
							<textarea
								id="id-homework-comment"
								class="labeled-field__input"
								v-model="attemptComment"
								placeholder="Напишите здесь свой комментарий"
							></textarea>
						</label>
						<button class="regular-btn start-btn mt-10" @click="submitAttemptComment" type="button">Отправить комментарий</button>
					</div>
				</div>
			</section>
		</div>
	</section>
</template>

<script>
export default {
	name: 'HomeworkAttempt',

	props: {
		homework_attempt: { type: Object },
		lecture_url: { type: String },
		reviewers: { type: Array },
		homework_attempt_comments_url: { type: String },
		homework_attempt_url: { type: String },
		list_statuses: { type: Array, required: true }
	},

	data() {
		return {
			selectedReviewer: this.homework_attempt.reviewer_id || '',
			selectedCrossChecker: this.homework_attempt.cross_checker_id || '',
			selectedStatus: this.homework_attempt.status,
			statuses: this.list_statuses,
			controlComment: '',
			attemptComment: '',
			isShowComments: false,
			isSubmitFields: {
				reviewer_id: false,
				cross_checker_id: false,
				status: false
			}
		};
	},

	mounted() {
		if (sessionStorage.isShowComments) {
			this.isShowComments = sessionStorage.isShowComments === 'true';
		}
	},

	watch: {
		isShowComments(newValue) {
			sessionStorage.isShowComments = newValue;
		},
	},

	methods: {
		formatDate(dateString) {
			const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
			return new Date(dateString).toLocaleDateString('ru-RU', options);
		},

		submitAttemptComment() {
			fetch(this.homework_attempt_comments_url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/javascript',
					'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				},
				body: JSON.stringify({
					body: this.attemptComment,
				}),
			})
				.then(response => response.json())
				.then(comment => {
					this.homework_attempt.homework_attempt_comments.push(comment);
					this.attemptComment = '';
				})
				.catch((error) => {
					console.error('Error:', error);
				});
		},

		submitControlComment() {
			this.updateHomeworkAttempt('control', this.controlComment);
		},

		submitStatusChange() {
			this.updateHomeworkAttempt('status', this.selectedStatus);
		},

		submitReviewerChange() {
			this.updateHomeworkAttempt('reviewer_id', this.selectedReviewer);
		},

		submitCrossCheckerChange() {
			this.updateHomeworkAttempt('cross_checker_id', this.selectedCrossChecker);
		},

		updateHomeworkAttempt(field, value) {
			let payload = { homework_attempt: {}};
			payload["homework_attempt"][field] = value;

			fetch(this.homework_attempt_url, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/javascript',
					'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				},
				body: JSON.stringify(payload),
			})
				.then(response => {
					if (!response.ok) {
						throw new Error('Network response was not ok');
					}
					return response.json();
				})
				.then(data => {
					if (data.success) {
						this.homework_attempt[field] = value;

						if (field === 'control') {
							this.controlComment = '';
						}

						this.showCheckSubmit(field);

						console.log(`${field} updated successfully`);
					} else {
						console.error(`Error updating ${field}:`, data.errors.join('; '));
						alert(`Ошибка обновления ${field}: ` + data.errors.join('; '));
					}
				})
				.catch((error) => {
					console.error('Error:', error);
					alert('Ошибка: ' + error.message);
				});
		},

		showComments() {
			this.isShowComments = !this.isShowComments;
		},

		showCheckSubmit(field) {
			this.isSubmitFields[field] = true;

			setTimeout(()=> {
				this.isSubmitFields[field] = false;
				console.log(field)
			}, 3000);
		}
	}

}
</script>
