<template>
	<section class="page-card scrollable scrollable--table">
		<table class="table table--tr-hover table--statistics table--homework">
			<thead class="table__thead">
				<tr class="table__tr">
					<th class="table__th" @click="sortedAttempts('status_with_translate', $event)" data-sorted="">
						<span>Статус ДЗ</span>
					</th>
					<th class="table__th" @click="sortedAttempts('name', $event)" data-sorted="">
						<span>Название ДЗ</span>
					</th>
					<th class="table__th" @click="sortedAttempts('company', $event)" data-sorted="">
						<span>Компания</span>
					</th>
					<th class="table__th" @click="sortedAttempts('student', $event)" data-sorted="">
						<span>Студент</span>
					</th>
					<th class="table__th" @click="sortedAttempts('reviewer', $event)" data-sorted="">
						<span>Проверяет</span>
					</th>
					<th class="table__th" @click="sortedAttempts('cross_checker', $event)" data-sorted="">
						<span>Кроссчекает</span>
					</th>
					<th class="table__th" @click="sortedAttempts('days_on_review', $event)" data-sorted="">
						<span>Дней на проверке</span>
					</th>
				</tr>
			</thead>
			<tbody class="table__body">
				<tr v-for="attempt in homeworkAttempts" @click="goToShowPage(attempt.id)" class="table__tr">
					<td class="table__td">
						<span class="icon icon--small icon--dot" :style="`--icon-color: ${getStatusColor(attempt.status)};`">
							{{ attempt.status_with_translate }}
						</span>
					</td>
					<td class="table__td">
						<span class="bold-font">{{ attempt.name }}</span>
					</td>
					<td class="table__td">
						{{ attempt.company }}
					</td>
					<td class="table__td">
						<a :href="`mailto:${attempt.student}`">{{ attempt.student }}</a>
					</td>
					<td class="table__td">
						<template v-if="attempt.reviewer">
							<a :href="`mailto:${attempt.reviewer}`">{{ attempt.reviewer }}</a>
						</template>
					</td>
					<td class="table__td">
						<template v-if="attempt.cross_checker">
							<a :href="`mailto:${attempt.cross_checker}`">{{ attempt.cross_checker }}</a>
						</template>
					</td>
					<td class="table__td">
						{{ attempt.days_on_review }}
					</td>
				</tr>
			</tbody>
		</table>
	</section>
</template>

<script>
export default {
	name: 'HomeworkAttempts',

	props: {
		homework_attempts: { type: Array }
	},

	mounted() {
		this.homeworkAttempts = this.getHomeworkAttempts();
	},

	data() {
		return {
			homeworkAttempts: []
		}
	},

	methods: {
		getHomeworkAttempts() {
			let arr = [];

			this.homework_attempts.forEach((attempt) => {
				let obj = {}
				obj.status = attempt.status ?? '';
				obj.status_with_translate = attempt.status_with_translate ? attempt.status_with_translate.text : '';
				obj.name = attempt.homework ? attempt.homework.lecture.name : '';
				obj.company = attempt.user ? attempt.user.company.name : '';
				obj.student = attempt.user ? attempt.user.email : '';
				obj.reviewer = attempt.reviewer ? attempt.reviewer.email : '';
				obj.cross_checker = attempt.cross_checker ? attempt.cross_checker.email : '';
				obj.days_on_review = attempt.created_at ? this.calculateDaysOnReview(attempt.created_at).toString() : '';
				arr.push(obj);
			});

			return arr;
		},

		calculateDaysOnReview(createdAt) {
			return Math.ceil((new Date() - new Date(createdAt)) / (1000 * 60 * 60 * 24));
		},

		goToShowPage(attemptId) {
			window.location.href = `/dashboard/homework_attempts/${attemptId}`;
		},

		getStatusColor(status) {
			switch(status) {
				case 'sent':
					return 'hsl(320, 90%, 50%)';
				case 'checking':
					return 'hsl(280, 90%, 50%)';
				case 'in_crosscheck':
					return 'hsl(60, 90%, 40%)';
				case 'checked':
					return 'hsl(220, 90%, 50%)';
				case 'sending':
					return 'hsl(180, 90%, 50%)';
				case 'success_done':
					return 'hsl(120, 90%, 40%)';
				case 'failed_done':
					return 'hsl(20, 90%, 50%)';
				default:
					return 'hsl(120, 90%, 20%)';
			}
		},

		sortedAttempts(column, e) {
			let el = e.currentTarget;

			if (el.dataset.sorted !== 'desc') {
				this.homeworkAttempts = this.homeworkAttempts.sort((a, b) => a[column].localeCompare(b[column]));
				el.dataset.sorted = 'desc';
				el.classList.add('selected');
				el.classList.remove('down');
			} else {
				this.homeworkAttempts = this.homeworkAttempts.sort((b, a) => a[column].localeCompare(b[column]));
				el.dataset.sorted = 'asc';
				el.classList.add('selected');
				el.classList.add('down');
			}
		}
	}
}
</script>
