<script>
import axios from "axios";

export default {
  name: "EditorQuizQuestionForm",
  template: `<slot v-bind="self" />`,

  props: {
    bodies: Array,
    quizShowRightAnswer: Boolean,
  },

  data() {
    return {
      editState: false, // Если открывается попап редактирования, то true, если создания вопроса, то false (Не реализовано)
      question: {
        question_type: "picture",
        time_limit_sec: 0,
        filling_type: "",
      },
      questionType: "",
      correctAnswers: [],
      showQuestionNumField: false,
      formElement: null,
      form_action: {},
      datepickers: {},
      form_utf8: "",
      authenticity_token: "",
      errors: {},
      questionTimeField: null,
      selectedFile: null,
    };
  },

  mounted() {
    this.emitter.on("show_quiz_question_form", this.showQuizQuestionForm);
    this.emitter.on("show_quiz_new_question_form", this.showNewQuizQuestionForm);
    this.authenticity_token = document.querySelector(
      'input[name="authenticity_token"]'
    ).value;
    this.setQuestionTimeField();
  },

  computed: {
    self() {
      return this;
    },
  },

  watch: {
    questionTimeField(val, val2) {
      if (!val) this.question.time_limit_sec = 0;
    },
  },

  methods: {
    setQuestionType(type) {
      this.question.question_type = type;
    },

    onFileChanged(event) {
      this.question.picture = event.target.files[0];
    },

    setQuestionTimeField() {
      this.questionTimeField = this.question.time_limit_sec > 0;
    },

    closePopup() {
      this.clearQuestion();
      this.$parent.closePopup();
    },

    clearQuestion() {
      console.log(1);
      this.question = {
        question_type: "none",
        time_limit_sec: 0,
      };
    },

    deleteQuizQuestion() {
      axios({
        response_type: "json",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
        method: "DELETE",
        url: this.form_action.url,

        data: {
          utf8: this.utf8,
          authenticity_token: this.authenticity_token,
          question: this.question,
        },
      })
        .then((response) => {
          this.$parent.loadingOff();
          this.closePopup();
        })
        .catch((errors) => {
          console.log(errors);
          this.$parent.loadingOff();
        });
    },

    submitQuizQuestionForm(url) {
      this.$parent.loadingOn();

      var n = 0;
      let data = {
        utf8: this.utf8,
        authenticity_token: this.authenticity_token,
        question: this.question,
      };
      axios({
        response_type: "json",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
        method: this.form_action.method,
        url: this.form_action.url,

        data: data,
      })
        .then((response) => {
          this.emitter.emit("set_root_question_items", response.data.questions);
          this.$parent.loadingOff();
          this.closePopup();
        })
        .catch((errors) => {
          console.log(errors);
          this.$parent.loadingOff();
        });
    },

    showNewQuizQuestionForm() {
      this.$parent.loadingOn();
    },

    showQuizQuestionForm(url) {
      this.clearQuestion();
      axios
        .request({
          response_type: "json",
          headers: { "X-Requested-With": "XMLHttpRequest" },
          url: url,
          method: "GET",
        })
        .then((response) => {
          this.question = response.data.question;
          this.form_action = response.data.form_action;
          this.setQuestionTimeField();
          this.$parent.openPopup();
        });
    },
  },
};
</script>
