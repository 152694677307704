const refferalCourses = [
	'academy-business-analyst-demo',
	'academy-data-science-demo',
	'academy-python-developer-demo',
	'academy-web-developer-demo',
	'academy-frontend-developer-demo',
	'academy-qa-pro-demo',
	'academy-qa-demo',
	'academy-methodist-basics-demo',
	'academy-online-course-creation-demo',
	'academy-hr-manager-demo',
	'academy-gen-director-demo',
	'academy-com-director-demo',
	'academy-salesman-demo',
	'academy-sales-demo',
	'academy-soft-skills-demo',
	'academy-graphic-designer-demo',
	'academy-internet-marketing-demo',
	'academy-copywriter-demo',
	'academy-mba-marketing-demo',
	'academy-accountant-demo',
	'academy-marketplace-manager-demo',
	'academy-product-manager-demo',
	'academy-project-manager-demo',
	'academy-chief-of-operations-demo',
	'academy-fin-officer-demo',
	'academy-wildberries-free',
	'academy-python-dev-beginner',
	'academy-data-analyst-free-demo',
	'academy-copywriter-start',
	'academy-marketplace-free',
	'academy-mini-accountant',
	'academy-web-developer-start',
	'academy-web-developer-first-project',
	'academy-company-finances',
	'academy-sales-team',
	'academy-product-quick-start',
	'academy-project-quick-start',
	'academy-internet-marketing-introduction',
	'academy-unit-metrics',
	'academy-revit-autodesk-demo',
	'academy-revit-autodesk',
	'academy-graphic-designer-webinar'
];

export default refferalCourses;
