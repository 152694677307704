<template>
	<div v-if="isShow" class="academy-nav-item">
		<a href="#id-referral-banner" class="academy-nav-link academy-nav-link--referral">Рекомендовать курсы</a>
	</div>
</template>

<script>
import refferalCourses from '../../common/refferal-courses.js';

export default {
	name: 'RefferalLink',

	props: {
		current_company: ''
	},

	data() {
		return {
			courses: refferalCourses,
			isShow: true
		}
	},

	mounted() {
		this.courses.forEach((course) => {
			if (course.name === this.current_company) {
				this.isShow = false;
			}
		});
	},
}
</script>
