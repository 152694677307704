<template>
	<div class="modal" :class="{ showing: isOpen }">
		<div class="modal__card modal__card--blank">
			<button class="remove-btn modal__close" @click="closePopup()" type="button">
				<span class="visually-hidden">{{ $t('buttons.close_popup') }}</span>
			</button>
			<div class="modal__section">
				<p class="modal__descr mb-5">{{ withDelimeter(course.topics) }}</p>
				<h3 class="modal__title">{{ course.name }}</h3>
			</div>
			<div class="modal__section relative">
				<div class="modal__course-preview preview-video-holder" :class="{ 'hide-control-bar': !this.intro}">
					<video ref="videoPlayer" class="video-js"></video>

					<div class="course-intro-mute-toggle" v-if="this.intro && muted" @click="unmute()">
						<span class="course-intro-mute-message">{{ $t('messages.mute_message') }}</span>
					</div>
				</div>
			</div>
			<div class="modal__section" v-if="showSkills">
				<h4 class="modal__subtitle">{{ $t('buttons.will_learn') }}</h4>
				<ul>
					<li v-for="(skill, index) in skills"
						:key="'skill-' + index"
						class="check-item"
					>{{ skill.body }}</li>
				</ul>
			</div>
			<div class="mt-20">
				<a :href="course_path" v-if="course_path" class="regular-btn action-btn mr-10">{{ $t('buttons.open_course_page') }}</a>
				<button class="regular-btn white-btn" @click="closePopup()">{{ $t('buttons.close') }}</button>
			</div>
		</div>
	</div>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css';

export default {
  name: "CoursePreviewPopup",

  data() {
    return {
      player: null,
      muted: true,
      videos: []
    }
  },

  methods: {
    closePopup() {
      this.$store.commit('trigger_course_preview_popup', false)
    },

    initVideoPlayer() {
      this.player = videojs(this.$refs.videoPlayer, this.videoOptions)
    },

    unmute() {
      this.player.muted(false)
      this.muted = false
    },

    withDelimeter(topics) {
      return (topics && topics.length > 0) ? topics.join(' | ') : ''
    }
  },

  computed: {
    videoOptions: function() {
      return {
        autoplay: 'muted',
        preload: false,
        controls: true,
        loop: true,
        mute: true,
        width: 560,
        height: 315,
        playbackRates: [0.5, 1, 1.25, 1.5, 2]
      }
    },

    isOpen: function() {
      return this.$store.state.course_preview_popup.is_open
    },

    course: function() {
      return this.$store.state.course_preview_popup.course
    },

    poster: function() {
      return this.course ? this.course.poster : false
    },

    intro: function() {
      return this.course ? this.course.intro : false
    },

    skills: function() {
      let skills = this.course.skills
      return skills
    },

    showSkills: function() {
      return this.skills && this.skills.length ? true : false
    },

    course_path: function() {
      return this.course ? this.course.course_path : false
    }
  },

  watch: {
    isOpen: function() {
      if (this.isOpen) {
        if (!this.player) this.initVideoPlayer()

        this.player.poster(this.poster)

        if (this.intro) {
          this.player.src(this.intro)
          this.player.play()
        }
      } else {
        this.muted = true
        this.player.pause()
      }
    }
  },
}
</script>
